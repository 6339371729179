import React from "react";
import { useFela } from "react-fela";
import useTranslation from "next-translate/useTranslation";

import { useTheme } from "@/hooks/useTheme";
import { Card } from "@/components/Card";
import { Link } from "@/components/Link";
import { Icon, StyledIconWrapper } from "@/components/Icon";
import { IDropdownChildren } from "@/components/Dropdown";
import { IToolsDropdownItem } from "./types";

const content: IToolsDropdownItem[] = [
  {
    text: "designConverter",
    href: "/",
    icon: "SWITCH-ICON",
  },
  {
    text: "figmaBackup",
    href: "https://backup.magicul.io/",
    icon: "BACKUP-ICON",
  },
];

export const ToolsDropdown: React.FC<IDropdownChildren> = ({ onClose }) => {
  const { css } = useFela();
  const { colors, bp } = useTheme();
  const { t } = useTranslation("navigation");

  return (
    <Card
      customStyle={{
        flex: 1,
        padding: "48px 16px 16px",
        borderRadius: 10,
        display: "flex",
        flexDirection: "column",
        backgroundColor: colors.WHITE,
        boxShadow: `20px 20px 80px ${colors.CETACEAN_BLUE}`,
        position: "absolute",
        top: 10,
        [bp.FROM_TABLET]: {
          width: 252,
          maxWidth: "100%",
          position: "relative",
          padding: 16,
        },
      }}
    >
      <div
        className={css({
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          justifyContent: "space-between",
          [bp.FROM_TABLET]: {
            justifyContent: "flex-start",
          },
        })}
      >
        {content.map(({ text, href, icon }) => {
          const isInternalLink = href === "/";
          const isActive = href === "/";
          return (
            <Link
              key={text}
              href={href}
              target={isInternalLink ? "" : "_blank"}
              rel={isInternalLink ? "" : "noreferrer"}
              onClick={onClose}
              customStyle={{
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
                flexDirection: "row",
                fontSize: 16,
                fontWeight: 600,
                lineHeight: "24px",
                padding: 8,
                width: "100%",
                cursor: "pointer",
                color: isActive ? colors.WHITE : colors.DARK_GREY,
                backgroundColor: isActive ? colors.PALE_PURLPE : colors.WHITE,
                borderRadius: 8,
                borderBottom: "none",
                nested: {
                  ":not(:last-child)": {
                    marginBottom: 4,
                  },
                },
                [bp.FROM_TABLET]: {
                  display: "flex",
                  nested: {
                    ":hover": {
                      background: colors.PALE_PURLPE,
                      color: colors.WHITE,
                      borderRadius: 8,
                    },
                  },
                },
              }}
            >
              <StyledIconWrapper
                wrapperStyle={{
                  width: 32,
                  height: 32,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 6,
                  backgroundColor: isActive ? "#576de5" : "#f2f4f5",
                  marginRight: 12,
                }}
                customStyle={{
                  nested: {
                    "> g": { stroke: isActive ? colors.WHITE : "#7D8894" },
                    "> path": { stroke: isActive ? colors.WHITE : "#7D8894" },
                  },
                }}
              >
                <Icon iconType={icon} />
              </StyledIconWrapper>
              <span>{t(`toolsDropdown.${text}`)}</span>
            </Link>
          );
        })}
      </div>
    </Card>
  );
};
