import React from "react";
import { useFela, CssFelaStyle } from "react-fela";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";

import { useTheme } from "@/hooks/useTheme";
import { useAuth } from "@/hooks/useAuth";
import { Link } from "@/components/Link";
import { Dropdown } from "@/components/Dropdown";
import { Icon, StyledIconWrapper } from "@/components/Icon";
import { DynamicContent } from "@/components/DynamicContent";
import { Button } from "@/components/Button";

import Image from "next/image";
import { MAX_PHONE_WIDTH } from "@/containers/Navbar/MobileNavbar";
import { useScreenWidth } from "@/hooks/useScreenWidth";
import { ConverterDropdown } from "./Dropdown/Converter";
import { MoreDropdown } from "./Dropdown/More";
import { NavbarLink } from "./Link";
import { ProfileDropdown } from "./Dropdown/Profile";
import { ToolsDropdown } from "./Dropdown/Tools";
import { SolutionsDropdown } from "./Dropdown/Solutions";
import { shouldUseSecondaryNavStyle } from "./utils";

interface IProps {
  isWithBackground?: boolean;
}

export const DesktopNavbar: React.FC<IProps> = ({
  isWithBackground = true,
}) => {
  const { css } = useFela();
  const { colors, bp } = useTheme();
  const router = useRouter();
  const screenWidth = useScreenWidth();
  const { t } = useTranslation();

  const { loading, loggedIn, login } = useAuth();

  const isSecondaryNavStyle = shouldUseSecondaryNavStyle(router.pathname);

  // had to use `any` because cannot index fela's IStyle and access breakpoints
  const dropdownContentStyles: any = {
    right: 0,
    left: 0,
    top: 73,
    [bp.FROM_TABLET]: {
      top: 35,
      left: "auto",
    },
  };

  const dropdownStyles: CssFelaStyle<unknown, unknown> = {
    color: isWithBackground ? colors.WHITE : colors.DARK_GREY,
    [bp.TO_DESKTOP]: {
      color: isSecondaryNavStyle ? colors.DARK_GREY : colors.WHITE,
    },
    nested: {
      "::after": {
        content: "none",
      },
    },
  };

  const linkStyles: CssFelaStyle<unknown, unknown> = {
    fontSize: 14,
    border: "none",
    fontWeight: "normal",
    textDecoration: "none",
    color: isWithBackground ? colors.WHITE : colors.DARK_GREY,
    nested: {
      ":hover": {
        textShadow: `0 0 .01px ${colors.DARK_GREY}, 0 0 .01px ${colors.DARK_GREY}, 0 0 .01px ${colors.DARK_GREY}`,
      },
    },
    [bp.TO_DESKTOP]: {
      color: isSecondaryNavStyle ? colors.DARK_GREY : colors.WHITE,
    },
  };

  const pathsArr = [
    "/converter/",
    "/file/",
    "/open-xd-file-old-corrupted",
    "/extract-images-from-xd",
  ];
  const isConverterPage = pathsArr.some(
    path => router?.pathname === "/" || router?.pathname?.includes(path),
  );

  if (screenWidth < MAX_PHONE_WIDTH) return null;

  return (
    <nav
      className={css({
        width: "100%",
        top: 0,
        padding: "0 10px",
        maxWidth: 1380,
        display: "flex",
        position: "relative",
        flexDirection: "column",
        justifyContent: "space-between",

        nested: {
          "@media (min-width: 1380px)": {
            left: "calc(50vw - 690px)",
          },
        },

        [bp.ONLY_MOBILE]: {
          display: "none",
        },

        [bp.FROM_TABLET]: {
          flexDirection: "row",
        },
      })}
    >
      <Link
        href="/"
        currentPath={router?.pathname}
        customStyle={{
          padding: 0,
          border: "none",
          [bp.ONLY_MOBILE]: {
            margin: "0 auto",
            transform: "translateX(-5px)",
          },
        }}
      >
        <Image
          priority
          height={40}
          width={isSecondaryNavStyle ? 126 : 130}
          src={
            isSecondaryNavStyle ? "/svgs/logo-inverted.svg" : "/svgs/logo.svg"
          }
          alt="Magicul Logo"
          className={css({
            [bp.ONLY_MOBILE]: {
              margin: "0 auto",
              minHeight: 40,
            },
          })}
        />
      </Link>

      <ul
        className={css({
          display: "flex",
          listStyleType: "none",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          margin: 0,
          padding: 0,
          [bp.FROM_DESKTOP]: {
            maxWidth: isConverterPage ? "48%" : "62%",
            width: "100%",
            minWidth: 450,
            flexWrap: isConverterPage ? "wrap" : "nowrap",
          },
        })}
      >
        <NavbarLink>
          <Dropdown
            title={t("navigation:products")}
            contentCss={{
              ...dropdownContentStyles,
              [bp.FROM_TABLET]: {
                ...dropdownContentStyles[bp.FROM_TABLET],
                left: 20,
                right: 20,
              },
            }}
            customStyle={dropdownStyles}
            dropdownIconColor={isWithBackground ? "light" : "dark"}
          >
            <ConverterDropdown />
          </Dropdown>
        </NavbarLink>

        <NavbarLink>
          <Dropdown
            title={t("navigation:solutions")}
            contentCss={{
              ...dropdownContentStyles,
              [bp.FROM_TABLET]: {
                ...dropdownContentStyles[bp.FROM_TABLET],
                right: 0,
                left: "35%",
              },
              [bp.FROM_DESKTOP]: {
                ...dropdownContentStyles[bp.FROM_DESKTOP],
                right: 0,
                left: "55%",
              },
            }}
            customStyle={dropdownStyles}
            dropdownIconColor={isWithBackground ? "light" : "dark"}
          >
            <SolutionsDropdown />
          </Dropdown>
        </NavbarLink>

        <NavbarLink>
          <Link
            href={
              router.pathname === "/figma-design-backup"
                ? "/pricing#backup"
                : "/pricing"
            }
            customStyle={linkStyles}
          >
            {t("navigation:pricing")}
          </Link>
        </NavbarLink>

        <NavbarLink>
          <Dropdown
            title={t("navigation:more")}
            contentCss={{
              ...dropdownContentStyles,
              [bp.FROM_TABLET]: {
                ...dropdownContentStyles[bp.FROM_TABLET],
                right: 0,
                left: "55%",
              },
            }}
            customStyle={dropdownStyles}
            dropdownIconColor={isWithBackground ? "light" : "dark"}
          >
            <MoreDropdown />
          </Dropdown>
        </NavbarLink>

        <DynamicContent>
          <NavbarLink
            customStyle={{
              nested: { ":hover": { borderBottomColor: "transparent" } },
            }}
          >
            <Link
              href="/files"
              customStyle={{
                ...linkStyles,
                nested: {
                  ":hover": {
                    borderBottomColor: "transparent",
                    textShadow: `0 0 .01px ${colors.DARK_GREY}, 0 0 .01px ${colors.DARK_GREY}`,
                  },
                },
              }}
            >
              {t("navigation:files")}
            </Link>
          </NavbarLink>
        </DynamicContent>
        <div
          className={css({
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          })}
        >
          <NavbarLink>
            <Dropdown
              titleIcon="TOOL-MENU"
              showDropdownIcon={false}
              iconStyles={{
                lineHeight: "10px",
                nested: {
                  "& > svg > path": {
                    fill: isSecondaryNavStyle ? colors.DARK_GREY : colors.WHITE,
                  },
                },
                [bp.FROM_DESKTOP]: {
                  nested: {
                    "& > svg > path": {
                      fill: isWithBackground ? colors.WHITE : colors.DARK_GREY,
                    },
                  },
                },
              }}
              contentCss={{
                ...dropdownContentStyles,
                [bp.FROM_TABLET]: {
                  ...dropdownContentStyles[bp.FROM_TABLET],
                  right: 0,
                },
              }}
              customStyle={{
                ...dropdownStyles,
                marginLeft: 12,
              }}
              dropdownIconColor={isWithBackground ? "light" : "dark"}
            >
              <ToolsDropdown />
            </Dropdown>
          </NavbarLink>

          {!loading && loggedIn ? (
            <Dropdown
              title={
                <StyledIconWrapper
                  width={18}
                  height={18}
                  customStyle={{
                    nested: {
                      "> path": {
                        stroke: colors.WHITE,
                      },

                      [bp.FROM_DESKTOP]: {
                        nested: {
                          "> path": {
                            stroke: isWithBackground
                              ? colors.WHITE
                              : colors.DARK_GREY,
                          },
                        },
                      },
                    },
                  }}
                >
                  <Icon iconType="USER" />
                </StyledIconWrapper>
              }
              contentCss={{
                ...dropdownContentStyles,
                top: 120,

                [bp.FROM_TABLET]: {
                  ...dropdownContentStyles[bp.FROM_TABLET],
                  width: 165,
                },
              }}
              customStyle={{
                ...dropdownStyles,
              }}
              dropdownIconColor={isWithBackground ? "light" : "dark"}
            >
              <ProfileDropdown />
            </Dropdown>
          ) : null}

          {!loading && !loggedIn ? (
            <NavbarLink>
              <div className={css({ display: "flex", alignItems: "center" })}>
                <Button
                  id="login"
                  onClick={() => login()}
                  customStyle={{
                    padding: "4px 15px",
                    height: 35,
                    letterSpacing: 0.5,
                    textTransform: "capitalize",
                    fontWeight: 400,
                  }}
                >
                  {t("navigation:login")}
                </Button>
              </div>
            </NavbarLink>
          ) : null}
        </div>
      </ul>
    </nav>
  );
};
