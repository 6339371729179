import React from "react";
import { useFela } from "react-fela";

import { IA } from "./types";
import { IWithCustomStyle } from "../../hooks/useTheme/types";
import { useTheme } from "../../hooks/useTheme";

type IProps = IA & IWithCustomStyle & JSX.IntrinsicElements["a"];

export const A = React.forwardRef<HTMLAnchorElement, IProps>(
  ({ customStyle = {}, children, color = "WHITE", ...rest }, ref) => {
    const { css } = useFela();
    const { colors } = useTheme();

    const currentColor = color in colors ? colors[color] : colors["WHITE"];

    return (
      <a
        ref={ref}
        className={css(
          {
            padding: "2px 0",
            color: currentColor,
            fontWeight: "bold",
            letterSpacing: 0.5,
            cursor: "pointer",
            textDecoration: "none",
            borderBottom: "2px solid transparent",
            transition: "border-bottom-color .15s",
            nested: {
              ":hover": {
                borderBottomColor: currentColor,
              },
            },
          },
          customStyle,
        )}
        {...rest}
      >
        {children}
      </a>
    );
  },
);
