import React from "react";
import { useFela, CssFelaStyle } from "react-fela";
import { useCookies } from "react-cookie";

import { useTheme } from "../../hooks/useTheme";
import { Button } from "../Button";
import { Link } from "../Link";

const cookiesAcceptedKey = "xd2sketch_cookies_accepted";

export const CookiesBanner: React.FC = () => {
  const { css } = useFela();
  const { bp, colors } = useTheme();

  const [cookies, setCookie] = useCookies([cookiesAcceptedKey]);

  if (cookies[cookiesAcceptedKey]) {
    return null;
  }

  const accept = () => {
    setCookie(cookiesAcceptedKey, true);
  };

  const buttonStyles: CssFelaStyle<any, any> = {
    textTransform: "capitalize",
    minWidth: "fit-content",
    padding: "1px 25px",
    height: 36,
  };

  return (
    <div
      className={css({
        position: "fixed",
        zIndex: 41,
        bottom: 50,
        left: "50%",
        transform: "translateX(-50%)",
        width: "85%",

        display: "flex",
        flexDirection: "column",
        padding: "20px 30px",

        backgroundColor: colors.NAVY,
        borderRadius: 8,

        [bp.FROM_LARGE_DESKTOP]: {
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "72%",
        },
      })}
    >
      <p
        className={css({
          color: colors.WHITE,
          marginBottom: 25,
          fontSize: 14,

          [bp.FROM_LARGE_DESKTOP]: {
            position: "relative",
            paddingLeft: 50,
            marginBottom: 0,
            marginRight: 10,
          },

          nested: {
            "&:before": {
              content: '"🍪"',
              marginRight: 5,

              [bp.FROM_LARGE_DESKTOP]: {
                marginRight: 0,
                position: "absolute",
                left: 0,
                top: "50%",
                transform: "translateY(-50%)",
                fontSize: 32,
              },
            },
          },
        })}
      >
        This website uses cookies to improve the experience. Essential cookies
        will be stored in order to run the website. You can accept or decline to
        store optional cookies.{" "}
        <Link href="/privacy" customStyle={{ textDecoration: "underline" }}>
          Privacy Policy
        </Link>
      </p>

      <div
        className={css({
          [bp.FROM_TABLET]: {
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            alignSelf: "flex-end",
          },
          [bp.FROM_LARGE_DESKTOP]: {
            alignSelf: "unset",
            flexShrink: 0,
          },
        })}
      >
        <Button
          onClick={accept}
          customStyle={{
            borderColor: colors.ORANGE,
            backgroundColor: colors.NAVY,
            color: colors.ORANGE,
            marginBottom: 15,
            ...buttonStyles,

            [bp.FROM_TABLET]: {
              marginBottom: 0,
              marginRight: 15,
            },
          }}
        >
          Only required
        </Button>
        <Button onClick={accept} customStyle={buttonStyles}>
          Accept all
        </Button>
      </div>
    </div>
  );
};
