const pagesWithoutBackground = [
  "/",
  new RegExp("/converter//*"),
  new RegExp(/^\/file\/&*/),
  "/open-xd-file-old-corrupted",
  "/inspector",
  "/extract-images-from-xd",
];

export const shouldHideBackground = (pathname: string): boolean => {
  let shouldHide = false;

  pagesWithoutBackground.forEach(url => {
    if (typeof url === "string" && url === pathname) {
      shouldHide = true;
    }

    if (url instanceof RegExp && url.test(pathname)) {
      shouldHide = true;
    }
  });

  return shouldHide;
};
