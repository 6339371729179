import React from "react";
import { useFela } from "react-fela";
import useTranslation from "next-translate/useTranslation";

import { useTheme } from "@/hooks/useTheme";
import { Card } from "@/components/Card";
import { Link } from "@/components/Link";
import { Icon, StyledIconWrapper } from "@/components/Icon";
import { IDropdownChildren } from "@/components/Dropdown";

import { content } from "./content";

declare const Intercom: (...args: unknown[]) => any;

export const MoreDropdown: React.FC<IDropdownChildren> = ({ onClose }) => {
  const { css } = useFela();
  const { colors, bp } = useTheme();
  const { t } = useTranslation();

  const handleEvent = (message?: string) => {
    if (typeof Intercom !== "undefined") {
      Intercom(
        "showNewMessage",
        message ? t(`navigation:moreDropdown.${message}`) : undefined,
      );
    }
  };

  const { groups, buttons } = content;

  return (
    <Card
      customStyle={{
        flex: 1,
        padding: "0px",
        borderRadius: 8,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: colors.WHITE,
        boxShadow: `20px 20px 80px ${colors.CETACEAN_BLUE}`,
        position: "absolute",
        top: 0,
        [bp.ONLY_MOBILE]: {
          boxShadow: "none",
          overflowY: "hidden",
          position: "relative",
        },
        [bp.FROM_TABLET]: {
          width: "450px",
          maxWidth: "100%",
          padding: "0px",
          position: "relative",
        },
      }}
    >
      <div
        className={css({
          display: "flex",
          flexDirection: "column",
          padding: "10px",
          flexWrap: "wrap",
          justifyContent: "space-between",
          [bp.FROM_TABLET]: {
            justifyContent: "flex-start",
          },
        })}
      >
        {groups.map(group => (
          <Link
            href={group.href}
            key={group.title}
            target={group.external ? "_blank" : ""}
            rel={group.external ? "noreferrer" : ""}
            onClick={onClose}
            customStyle={{
              display: "flex",
              textDecoration: "none",
              alignItems: "flex-start",
              flexDirection: "row",
              padding: "20px",
              width: "100%",
              cursor: "pointer",
              marginBottom: 5,
              [bp.ONLY_MOBILE]: {
                padding: "20px 0",
              },
              [bp.FROM_TABLET]: {
                display: "flex",
                nested: {
                  ":hover": {
                    background: colors.GHOST_WHITE1,
                    borderRadius: 8,
                  },
                  ":not(:last-child)": {
                    marginRight: "32px",
                  },
                },
              },
            }}
          >
            <StyledIconWrapper customStyle={{ marginRight: 26 }}>
              <Icon iconType={group.icon} />
            </StyledIconWrapper>
            <div
              className={css({
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
              })}
            >
              <h3
                className={css({
                  fontSize: 14,
                  fontWeight: "normal",
                  marginTop: 0,
                  color: colors.DARK_GREY,
                  display: "flex",
                  alignItems: "center",
                })}
              >
                <strong>
                  {t(`navigation:moreDropdown.${group.title}` as string)}
                </strong>
              </h3>
              <p
                className={css({
                  fontSize: 14,
                  color: colors.DARK_GREY,
                  fontWeight: "normal",
                })}
              >
                {t(
                  `navigation:moreDropdown.${group.title}Description` as string,
                )}
              </p>
            </div>
          </Link>
        ))}
      </div>
      {buttons && buttons.length > 0 && (
        <div
          className={css({
            display: "flex",
            flexDirection: "row",
            background: colors.GHOST_WHITE1,
            justifyContent: "space-around",
            alignItems: "center",
            height: 49,
            borderRadius: 8,
          })}
        >
          {buttons.map(button => (
            <a
              onClick={() => handleEvent(button.intercomMessage)}
              className={css({
                display: "flex",
                alignItems: "center",
                flex: "1 0 auto",
                paddingLeft: 30,
                textDecoration: "none",
                cursor: "pointer",
                flexDirection: "row",
                borderRadius: 8,
                [bp.FROM_TABLET]: {
                  display: "flex",
                  nested: {
                    ":hover": {
                      background: "#f1f1f5",
                    },
                  },
                },
              })}
              key={button.title}
              role="button"
            >
              <StyledIconWrapper
                customStyle={{ marginRight: 26 }}
                wrapperStyle={{ display: "flex" }}
              >
                <Icon iconType={button.icon} />
              </StyledIconWrapper>
              <div
                className={css({
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                })}
              >
                <h3
                  className={css({
                    fontSize: 14,
                    fontWeight: "normal",
                    color: colors.DARK_GREY,
                    display: "flex",
                    alignItems: "center",
                  })}
                >
                  <strong>
                    {t(`navigation:moreDropdown.${button.title}`)}
                  </strong>
                </h3>
              </div>
            </a>
          ))}
        </div>
      )}
    </Card>
  );
};
