import React from "react";
import { useFela } from "react-fela";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";

import { ProductHuntBanner } from "@/components/ProductHuntBanner";
import { DesktopNavbar } from "@/containers/Navbar";

import { useTheme } from "@/hooks/useTheme";
import { MobileNavbar } from "@/containers/Navbar/MobileNavbar";
import { IHeader } from "./types";
import { shouldHideBackground } from "./utils";

type IProps = IHeader & JSX.IntrinsicElements["header"];

export const Header: React.FC<IProps> = ({ bannerText, ...rest }) => {
  const { css } = useFela();
  const { colors, bp } = useTheme();
  const { t } = useTranslation();
  const { pathname } = useRouter();

  const isWithBackground = !shouldHideBackground(pathname);

  return (
    <header
      id="HEADER"
      className={css({
        position: isWithBackground ? "relative" : "absolute",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        [bp.FROM_TABLET]: {
          minHeight: 84,
        },
        nested: isWithBackground
          ? {
              [bp.ONLY_MOBILE]: {
                nested: {
                  "&:before": {
                    content: "none",
                  },
                },
              },
              "&:before": {
                content: "''",
                position: "absolute",
                height: "100%",
                width: "100%",
                background: `linear-gradient(84deg, ${colors.GRADIENT_BLUE_DARK} -32%, ${colors.GRADIENT_PURPLE} 94%)`,
              },
            }
          : undefined,
      })}
      {...rest}
    >
      {bannerText && <ProductHuntBanner text={t(bannerText as string)} />}
      <DesktopNavbar isWithBackground={isWithBackground} />
      <MobileNavbar />
    </header>
  );
};
