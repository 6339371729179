import { useFela } from "react-fela";
import React, { useEffect, useState } from "react";
import { useTheme } from "@/hooks/useTheme";
import { useRouter } from "next/router";
import { useAuth } from "@/hooks/useAuth";
import Image from "next/image";
import { DropdownToggler } from "@/containers/Navbar/DropdownToggler";
import { ConverterDropdown } from "@/containers/Navbar/Dropdown/Converter";
import { SolutionsDropdown } from "@/containers/Navbar/Dropdown/Solutions";
import { Link } from "@/components/Link";
import { MoreDropdown } from "@/containers/Navbar/Dropdown/More";
import { Icon } from "@/components/Icon";
import { Button } from "@/components/Button";
import { useScreenWidth } from "@/hooks/useScreenWidth";

export const MAX_PHONE_WIDTH = 767;

export const MobileNavbar = () => {
  const { css } = useFela();
  const [open, setOpen] = useState(false);
  const screenWidth = useScreenWidth();
  const { bp, colors } = useTheme();
  const router = useRouter();
  const { login, loggedIn } = useAuth();

  useEffect(() => {
    const handleRouteChange = () => setOpen(false);

    if (!router.events) return;

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router]);

  if (screenWidth >= MAX_PHONE_WIDTH) return null;

  return (
    <nav
      className={css({
        maxHeight: "100vh",
        position: "fixed",
        zIndex: 100,
        top: 0,
        width: "100vw",
        left: 0,
        [bp.FROM_TABLET]: {
          display: "none",
        },
      })}
    >
      <div
        className={css({
          backgroundColor: colors.GRADIENT_BLUE,
        })}
      >
        <div
          className={css({
            display: "flex",
            alignItems: "center",
            minHeight: 84,
            justifyContent: "space-between",
            padding: 20,
          })}
        >
          <Link
            href="/"
            customStyle={{
              display: "flex",
              alignItems: "center",
              nested: {
                "&:hover": {
                  borderBottom: "none",
                },
              },
            }}
          >
            <Image
              priority
              height={32}
              width={104}
              src="/svgs/logo.svg"
              alt="Magicul Logo"
              className={css({
                [bp.ONLY_MOBILE]: {
                  margin: "0 auto",
                  minHeight: 40,
                },
              })}
            />
          </Link>
          <div
            className={css({
              height: 30,
              cursor: "pointer",
              display: "flex",
              placeItems: "center",
            })}
            role="button"
            onClick={() => setOpen(open => !open)}
          >
            <div
              className={css({
                width: 14,
                height: 2,
                backgroundColor: open ? "transparent" : "#fff",
                transition: "all 0.3s linear",
                borderRadius: 12,
                position: "relative",
                nested: {
                  "&::before": {
                    content: '""',
                    height: 2,
                    backgroundColor: "#fff",
                    position: "absolute",
                    top: open ? "0" : "-5px",
                    left: 0,
                    right: 0,
                    transition: "all 0.2s ease",
                    transform: open ? "rotate(-45deg)" : "none",
                    borderRadius: 12,
                  },
                  "&::after": {
                    content: '""',
                    height: "2px",
                    backgroundColor: "#fff",
                    position: "absolute",
                    top: open ? "0" : "5px",
                    left: 0,
                    right: 0,
                    transition: "all 0.2s ease",
                    transform: open ? "rotate(45deg)" : "none",
                    borderRadius: 12,
                  },
                },
              })}
            />
          </div>
        </div>
        {open && (
          <div
            className={css({
              backgroundColor: colors.WHITE,
              position: "fixed",
              bottom: 0,
              width: "100vw",
              left: 0,
              top: 84,
              gap: 10,
              padding: 20,
              display: "flex",
              flexDirection: "column",
              overflowY: "auto",
            })}
          >
            <DropdownToggler title="Products">
              <ConverterDropdown />
            </DropdownToggler>
            <DropdownToggler title="Solutions">
              <SolutionsDropdown />
            </DropdownToggler>
            <Link href="/pricing">
              <DropdownToggler title="Pricing" />
            </Link>
            <DropdownToggler title="More">
              <MoreDropdown />
            </DropdownToggler>
            <div
              className={css({
                marginTop: "auto",
                display: "flex",
                flexDirection: "column",
                gap: 10,
                paddingTop: 20,
                borderTop: `1px solid #EAECF0`,
              })}
            >
              {loggedIn ? (
                <Link
                  href="/account"
                  customStyle={{
                    display: "flex",
                    gap: 10,
                    alignItems: "center",
                    padding: "10px 0",
                  }}
                >
                  <span
                    className={css({
                      maxWidth: 20,
                      maxHeight: 20,
                    })}
                  >
                    <Icon iconType="USER-DARK" />
                  </span>
                  <span
                    className={css({ color: colors.BLACK, fontWeight: 600 })}
                  >
                    My account
                  </span>
                </Link>
              ) : (
                <Button
                  customStyle={{
                    textTransform: "none",
                    fontWeight: 600,
                  }}
                  onClick={() => login()}
                >
                  Login or Sign up
                </Button>
              )}

              <Button
                variant="OUTLINED"
                customStyle={{
                  borderColor: colors.LIGHT_GRAY,
                  color: colors.GRAY,
                  fontWeight: 600,
                  textTransform: "none",
                }}
                onClick={() => {
                  window.open("https://backup.magicul.io");
                }}
              >
                Switch to Figma Backup Tool
              </Button>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};
