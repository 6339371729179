import React from "react";
import { CssFelaStyle, useFela } from "react-fela";
import { useForm } from "react-hook-form";
import useTranslation from "next-translate/useTranslation";

import { Input } from "@/components/Input";
import { Button } from "@/components/Button";
import { Heading } from "@/components/Heading";
import { useTheme } from "@/hooks/useTheme";
import { emailRegexp } from "@/utils/validateEmail";
import { Image } from "@/components/Image";
import { sendSalesInquiryForm } from "@/services/salesInquiryService";
import { StyledIconWrapper, Icon } from "@/components/Icon";
import { IWithCustomStyle } from "@/hooks/useTheme/types";

import { useRouter } from "next/router";
import { ErrorMessage } from "./ErrorMessage";
import { ISalesInquiryFormData } from "./types";

type IStatus = "IDLE" | "SUCCESS" | "ERROR" | "PENDING";

export enum FormSourceType {
  PRICING_SALES_INQUIRY = "PRICING_SALES_INQUIRY",
  ENTERPRISE_INQUIRY = "ENTERPRISE_INQUIRY",
  PLUGIN_INQUIRY = "PLUGIN_INQUIRY",
  BACKUP_MORE_INQUIRY = "BACKUP_MORE_INQUIRY",
  BACKUP_ENTERPRISE_CARD = "BACKUP_ENTERPRISE_CARD",
  ENTERPRISE_LANDING_PAGE_INQUIRY = "ENTERPRISE_LANDING_PAGE_INQUIRY",
  REQUEST_DEMO = "REQUEST_DEMO",
  MIRO_BACKUP_INQUIRY = "MIRO_BACKUP_INQUIRY",
  AIRTABLE_BACKUP_INQUIRY = "AIRTABLE_BACKUP_INQUIRY",
  FOR_AGENCIES_INQUIRY = "FOR_AGENCIES_INQUIRY",
  FOR_ENTERPRISES_INQUIRY = "FOR_ENTERPRISES_INQUIRY",
}

type IInquiryForm = {
  sourceId?: FormSourceType;
};

export const InquiryForm: React.FC<IInquiryForm & IWithCustomStyle> = ({
  customStyle = {},
  sourceId,
}) => {
  const { css } = useFela();
  const { bp, colors } = useTheme();
  const { t } = useTranslation("inquiryForm");
  const router = useRouter();

  const [status, setStatus] = React.useState<IStatus>("IDLE");

  const {
    register,
    handleSubmit,
    errors,
    reset,
  } = useForm<ISalesInquiryFormData>();

  const isIdle = status === "IDLE";
  const isSuccess = status === "SUCCESS";
  const isError = status === "ERROR";
  const isPending = status === "PENDING";

  const handleFormSubmit = handleSubmit(data => {
    setStatus("PENDING");
    data.pageUrl = window.location.href;
    data.sourceId = sourceId || "";
    sendSalesInquiryForm(data);
    reset();
    setStatus("SUCCESS");
    router.query.formSubmitted = "true";
    router.push(router);
  });

  const companySizeOptions = React.useMemo(() => {
    const companySizes = ["1-20", "20-100", "100-500", "500-2000", "2000+"];
    const opts = companySizes.map(size => ({
      value: size,
      label: t("employees", { amount: size }),
    }));
    opts.unshift({ value: "", label: t("companySize") });

    return opts;
  }, [t]);

  const userAccountsOptions = React.useMemo(() => {
    const userAccounts = ["1-5", "5-10", "10-30", "30-100", "100+"];
    const opts = userAccounts.map(count => ({
      value: count,
      label: t("users", { amount: count }),
    }));
    opts.unshift({ value: "", label: t("userAccounts") });

    return opts;
  }, [t]);

  const selectStyles: CssFelaStyle<any, any> = {
    height: 43,
    width: "100%",
    padding: "6px 14px",
    appearance: "none",
    color: "#777",
    letterSpacing: 0.5,
    borderRadius: 4,
    fontWeight: "normal",
    borderColor: colors.STARDUST,
    backgroundColor: colors.WHITE,
    backgroundImage: "url(/svgs/caret.svg)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right 12px center",

    nested: {
      ":focus-visible": {
        outline: "none",
      },
    },
  };

  return (
    <div
      id="salesInquiryForm"
      className={css(
        {
          display: "flex",
          flexDirection: "column",
          [bp.FROM_TABLET]: {
            flexDirection: "row",
          },
          [bp.FROM_DESKTOP]: { padding: 20 },
        },
        customStyle,
      )}
    >
      <div className={css({ flex: 1 })}>
        <Heading
          as="h2"
          customStyle={{
            marginBottom: 12,
            [bp.FROM_DESKTOP]: {
              fontSize: 48,
              marginBottom: 12,
            },
          }}
        >
          {t("title")}
        </Heading>

        <p
          className={css({
            fontSize: 14,
            color: colors.DARK_GREY,
          })}
        >
          {t("subtitle")}
        </p>

        <form
          onSubmit={isPending ? undefined : handleFormSubmit}
          className={css({
            marginTop: 25,
            display: "flex",
            flexDirection: "column",
            position: "relative",
          })}
        >
          <Input
            type="text"
            placeholder={t("fields.name")}
            name="name"
            ref={register({
              required: {
                value: true,
                message: "name",
              },
            })}
            customStyle={{ marginBottom: 30 }}
          />

          {errors.name && (
            <ErrorMessage>{t(`errors.${errors.name.message}`)}</ErrorMessage>
          )}

          <Input
            type="text"
            placeholder={t("fields.companyName")}
            name="companyName"
            ref={register({
              required: {
                value: true,
                message: "companyName",
              },
            })}
            customStyle={{ marginBottom: 30 }}
          />

          {errors.companyName && (
            <ErrorMessage>
              {t(`errors.${errors.companyName.message}`)}
            </ErrorMessage>
          )}

          <Input
            type="email"
            placeholder={t("fields.email")}
            name="email"
            ref={register({
              required: {
                value: true,
                message: "emailEmpty",
              },
              pattern: {
                value: emailRegexp,
                message: "emailInvalid",
              },
            })}
            customStyle={{ marginBottom: 30 }}
          />

          {errors.email && (
            <ErrorMessage>{t(`errors.${errors.email.message}`)}</ErrorMessage>
          )}

          <Input
            type="tel"
            placeholder={t("fields.phone")}
            name="phone"
            ref={register({
              required: {
                value: true,
                message: "phone",
              },
            })}
            customStyle={{ marginBottom: 30 }}
          />

          {errors.phone && (
            <ErrorMessage>{t(`errors.${errors.phone.message}`)}</ErrorMessage>
          )}

          <div className={css({ marginBottom: 30 })}>
            <select
              name="companySize"
              className={css(selectStyles)}
              ref={register({
                required: {
                  value: true,
                  message: "companySize",
                },
              })}
            >
              {companySizeOptions.map(opt => (
                <option value={opt.value} key={opt.value}>
                  {opt.label}
                </option>
              ))}
            </select>
          </div>

          {errors.companySize && (
            <ErrorMessage>
              {t(`errors.${errors.companySize.message}`)}
            </ErrorMessage>
          )}

          <div className={css({ marginBottom: 30 })}>
            <select
              name="userAccounts"
              className={css(selectStyles)}
              ref={register({
                required: {
                  value: true,
                  message: "userAccounts",
                },
              })}
            >
              {userAccountsOptions.map(opt => (
                <option value={opt.value} key={opt.value}>
                  {opt.label}
                </option>
              ))}
            </select>
          </div>

          {errors.userAccounts && (
            <ErrorMessage>
              {t(`errors.${errors.userAccounts.message}`)}
            </ErrorMessage>
          )}

          <label className={css({ display: "flex", marginBottom: 30 })}>
            <Input
              type="checkbox"
              name="conditions"
              ref={register({
                required: {
                  value: true,
                  message: "conditions",
                },
              })}
            />
            <p
              className={css({
                fontSize: 14,
                color: colors.DARK_GREY,
              })}
            >
              {t("fields.conditions")}
            </p>
          </label>

          {errors.conditions && (
            <ErrorMessage>
              {t(`errors.${errors.conditions.message}`)}
            </ErrorMessage>
          )}

          <Button
            type="submit"
            disabled={isPending}
            customStyle={{ textTransform: "none" }}
          >
            {t("submit")}
          </Button>
        </form>
      </div>

      <div
        className={css({
          flex: 1,
          marginTop: 50,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          [bp.FROM_TABLET]: {
            marginTop: 0,
          },
        })}
      >
        {(isIdle || isPending) && (
          <Image
            alt="Magician"
            src="/svgs/magician-single.svg"
            customStyle={{
              [bp.FROM_TABLET]: { transform: "translateY(-15px)" },
            }}
          />
        )}

        {isSuccess && (
          <div
            id="salesInquiryForm__success"
            className={css({
              maxWidth: 190,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            })}
          >
            <StyledIconWrapper
              height={70}
              width={70}
              wrapperStyle={{ marginBottom: 20 }}
            >
              <Icon iconType="CHECK_2" />
            </StyledIconWrapper>

            <p
              className={css({
                fontSize: 14,
                color: colors.DARK_GREY,
                letterSpacing: 0.5,
                textAlign: "center",
                lineHeight: 1.7,
              })}
            >
              {t("submitted")}
            </p>
          </div>
        )}

        {isError && (
          <StyledIconWrapper height={70} width={70}>
            <Icon iconType="ERROR-ICON" />
          </StyledIconWrapper>
        )}
      </div>
    </div>
  );
};
