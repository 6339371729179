import React from "react";
import { useRouter } from "next/router";

import { login, getProfile, logout } from "@/services/authService";

import { IAuth, IUser } from "./types";
import { initialAuthData, AuthContext } from "./context";

export const useAuth = () => {
  const context = React.useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
};

export const AuthProvider: React.FC = props => {
  const router = useRouter();

  const [state, setState] = React.useState<IAuth>(initialAuthData);

  React.useEffect(() => {
    getProfile()
      .then((profile: IUser) => {
        if (profile) {
          setState(prevState => ({
            ...prevState,
            user: profile,
            loggedIn: true,
          }));
        }
      })
      .finally(() => {
        setState(prevState => ({
          ...prevState,
          loading: false,
        }));
      });
  }, []);

  const contextValue: IAuth = {
    ...state,
    setAuthState: setState,

    login: (shouldRedirect = true) => {
      return login(setState)
        .then(() => {
          if (shouldRedirect) {
            router.push("/account?no-refresh=true", "/account");
          }
        })
        .finally(() =>
          setState(prevState => ({
            ...prevState,
            loading: false,
          })),
        );
    },
    logout: () => {
      logout(setState).then(() => {
        const protectedRoutes = ["/account", "/files"];

        if (protectedRoutes.includes(router.pathname)) {
          router.push("/");
        }
      });
    },
    refreshAccount: () => {
      getProfile().then((profile: IUser) => {
        if (profile) {
          setState(prevState => ({
            ...prevState,
            user: profile,
          }));
        }
      });
    },
  };

  return <AuthContext.Provider value={contextValue} {...props} />;
};
