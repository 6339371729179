import { Badge } from "@/components/Badge";
import { Icon, StyledIconWrapper } from "@/components/Icon";
import { Heading } from "@/components/Heading";
import React, { FC, useState } from "react";
import { useFela } from "react-fela";
import { useTheme } from "@/hooks/useTheme";
import { IConverterDropdownGroup } from "@/containers/Navbar/Dropdown/Converter/types";
import useTranslation from "next-translate/useTranslation";
import AnimateHeight from "react-animate-height";
import { landingPagesMap } from "@/containers/Converter/maps";
import { useRouter } from "next/router";
import { mergeConversionType } from "@/containers/Converter/common/helpers";
import { Link } from "@/components/Link";

type IConverterOption = {
  item: IConverterDropdownGroup;
  onClose?: () => void;
  visible?: boolean;
};

export const ConverterOption: FC<IConverterOption> = ({
  item,
  onClose,
  visible,
}) => {
  const { css } = useFela();
  const { bp, colors } = useTheme();
  const { t } = useTranslation();
  const router = useRouter();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={css({
        padding: 12,
        background: isHovered ? colors.LIGHT_GRAY1 : "transparent",
        borderRadius: 8,
        cursor: "pointer",
      })}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className={css({
          display: "flex",
          alignItems: "start",
          cursor: "pointer",
        })}
      >
        <Badge
          size={48}
          customStyle={{
            flexShrink: 0,
            backgroundColor: "#F5F3FF",
            marginRight: 16,
          }}
        >
          <StyledIconWrapper
            customStyle={{ width: 24, height: 24, display: "flex" }}
          >
            {visible && <Icon iconType={item.icon} />}
          </StyledIconWrapper>
        </Badge>
        <div>
          <Heading
            as="h4"
            customStyle={{
              fontSize: 14,
              lineHeight: "22px",
              fontWeight: 700,
              color: colors.DARK_GREY,
              display: "flex",
              alignItems: "center",
            }}
          >
            {t(`fileTypes:${item.sourceFileType}`)}
          </Heading>
          <p
            className={css({
              marginTop: 2,
              fontSize: 14,
              lineHeight: "16px",
              letterSpacing: 0.25,
              color: colors.GRAY,
            })}
          >
            {t(`navigation:converterDropdown.subtitle.${item.sourceFileType}`)}
          </p>
        </div>
      </div>
      <AnimateHeight height={isHovered ? "auto" : 0} duration={400}>
        <ul
          className={css({
            marginTop: 10,
            padding: 0,
            listStyleType: "none",
          })}
        >
          {item.targetFileTypes.map(target => {
            const conversionType = mergeConversionType(
              item.sourceFileType,
              target.targetFileType,
            );
            return (
              <li
                key={target.targetFileType}
                className={css({
                  marginTop: 12,
                  [bp.FROM_TABLET]: {
                    marginTop: 4,
                    alignItems: "center",
                  },
                })}
              >
                <Link
                  currentPath={router.route}
                  onClick={onClose}
                  href={landingPagesMap[conversionType]}
                  customStyle={{
                    fontSize: 14,
                    fontWeight: 400,
                    lineHeight: "16px",
                    letterSpacing: 0.25,
                    transition: "color .15s",
                    color: colors.PURLPE,
                    whiteSpace: "nowrap",
                    nested: {
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    },
                  }}
                >
                  {t("navigation:converterDropdown.convert", {
                    from: t(`fileTypes:${item.sourceFileType}` as string),
                    to: t(`fileTypes:${target.targetFileType}` as string),
                  })}
                </Link>
              </li>
            );
          })}
        </ul>
      </AnimateHeight>
    </div>
  );
};
