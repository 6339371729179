import React from "react";
import { useFela } from "react-fela";
import useTranslation from "next-translate/useTranslation";

import { IIconType } from "@/icons";
import { useTheme } from "@/hooks/useTheme";
import { Link } from "@/components/Link";
import { Icon, StyledIconWrapper } from "@/components/Icon";

import { IFooterLink } from "../types";

interface IProps {
  links: IFooterLink[];
  icon?: IIconType;
  namespace: "menu" | "guides";
}

export const Links: React.FC<IProps> = ({ namespace, links, icon }) => {
  const { css } = useFela();
  const { bp, colors } = useTheme();
  const { t } = useTranslation();

  if (!links.length) {
    return null;
  }

  return (
    <ul
      className={css({
        margin: 0,
        padding: 0,
        marginTop: 5,
        listStyleType: "none",
        [bp.ONLY_SMALL_MOBILE]: {
          maxWidth: 75,
        },
      })}
    >
      {links.map(link => (
        <li
          key={`FooterAction-${link.href}`}
          className={css({
            display: "flex",
            alignItems: "center",
            marginBottom: 16,
            nested: {
              "> a": {
                textTransform: "initial",
              },

              "> a > div": {
                transition: "transform .3s ease",
              },

              ":hover > a > div": {
                transform: "translateX(10px)",
              },
            },
          })}
        >
          <Link
            href={link.href}
            target={link.target ?? undefined}
            customStyle={{
              display: "flex",
              borderBottom: "none",
              nested: {
                "> span": { borderBottom: "1px solid transparent" },
                ":hover > span": { borderBottomColor: colors.WHITE },
              },
            }}
          >
            <span>{t(`footer:${namespace}.${link.title}` as any)}</span>
            {icon && (
              <StyledIconWrapper customStyle={{ marginLeft: 10 }}>
                <Icon iconType={icon} />
              </StyledIconWrapper>
            )}
          </Link>
        </li>
      ))}
    </ul>
  );
};
