import { useState, useEffect } from "react";

const FALLBACK_WINDOW_SIZE = 1920;

export const useScreenWidth = () => {
  const [screenWidth, setScreenWidth] = useState(FALLBACK_WINDOW_SIZE);

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    window?.addEventListener("resize", handleResize);
    setScreenWidth(window.innerWidth);

    return () => window?.removeEventListener("resize", handleResize);
  }, []);

  return screenWidth;
};
