import React from "react";

export type IIconType =
  | "REFRESH"
  | "PIGGY"
  | "EYE"
  | "BOX"
  | "SHIELD"
  | "LOCK"
  | "FILE-SUPPORT"
  | "BOLT"
  | "STAR-1"
  | "STAR-2"
  | "STAR-3"
  | "STAR-4"
  | "ADOBEXD"
  | "FIGMA"
  | "SKETCH"
  | "CANVA"
  | "FACEBOOK"
  | "TWITTER"
  | "YOUTUBE"
  | "SHEETS"
  | "TV"
  | "ARROW-RIGHT"
  | "ARROW-RIGHT-GREY"
  | "ARROW-RIGHT-SMALL"
  | "ARROW-DOWN"
  | "REFRESH-2"
  | "ADOBEXD_FILE"
  | "PLUS2"
  | "INFO"
  | "INFO-EMPTY"
  | "SKETCH_FILE"
  | "FIGMA_FILE"
  | "FIGJAM_FILE"
  | "PSD_FILE"
  | "PDF_FILE"
  | "AI_FILE"
  | "CLOSE"
  | "CONVERTER-ICON"
  | "SHIELD-2"
  | "LOCK-2"
  | "PLUGIN"
  | "CHECK-ICON"
  | "CHECK-ICON-EMPTY"
  | "ERROR-ICON"
  | "SCROLL-DOWN-ICON"
  | "IMAGE-ICON"
  | "IMAGE-RED-ICON"
  | "FOLDER-ICON"
  | "FOLDER-SIMPLE-ICON"
  | "PEN-ICON"
  | "TIP-ARROW-ICON"
  | "TIP-ARROW-ICON-BIG"
  | "QUOTES-ICON"
  | "CHAT-ICON"
  | "CHECK_2"
  | "FLOW-ICON"
  | "SYNC-ICON"
  | "SWITCH-ICON"
  | "TOGETHER-ICON"
  | "COMPANY-ICON"
  | "SHARE-ICON"
  | "SPREAD-ICON"
  | "DESIGN-TOOL-ICON"
  | "LOCK-DOT-ICON"
  | "INTEGRATION-ICON"
  | "SYNC-RED-ICON"
  | "TIME-ICON"
  | "TIME-BLUE-ICON"
  | "CHECK-GREEN-ICON"
  | "CHECK-CIRCLED-EMPTY"
  | "CHECK-CIRCLED-ICON"
  | "MEDIUM"
  | "ARROW-WHITE"
  | "FILE-NOT-FOUND-ICON"
  | "CONNECT-FIGMA-ICON"
  | "SLACK"
  | "SPINNER-ICON"
  | "PHOTOSHOP"
  | "AI"
  | "PDF"
  | "OWL"
  | "OVAL"
  | "OPEN_EXTERNAL"
  | "ENTERPRISE-ICON"
  | "HELP-ICON"
  | "PRIVACY-ICON"
  | "DEMO-ICON"
  | "CONTACT-ICON"
  | "PLAY-ICON-BIG"
  | "BLOG-ICON"
  | "DOWNLOAD-ICON"
  | "NO-PREVIEW-ICON"
  | "GOOGLE-SLIDES"
  | "KEYNOTE"
  | "GOOGLE-SLIDES-FILE-ICON"
  | "SQUARE-ICON"
  | "T-ICON"
  | "OUTDATED-ICON"
  | "CORRUPTED-ICON"
  | "EPS_FILE"
  | "BACKUP-ICON"
  | "GLOBE-ICON"
  | "USER"
  | "USER-DARK"
  | "LETTER"
  | "LOGOUT"
  | "CLOSE-CIRCLED-ICON"
  | "PLUS-CIRCLED-ICON"
  | "RHOMB-ICON"
  | "ARROW-POINTER-ICON"
  | "BALANCE-ICON"
  | "FREE-ICON"
  | "FILE-ICON"
  | "COMPONENTS-ICON"
  | "TRIANGLE-UP"
  | "TRIANGLE-DOWN"
  | "SHIELD-ALERT"
  | "WARNING"
  | "INDESIGN"
  | "TRASH"
  | "GEAR"
  | "EYE-VISIBLE"
  | "EYE-INVISIBLE"
  | "POWERPOINT"
  | "POWERPOINT-FILE-ICON"
  | "CLOCK"
  | "FILL-ICON"
  | "CANVA-FILE-ICON"
  | "INDESIGN-FILE-ICON"
  | "FIGMA-BACKUP"
  | "AIRTABLE-BACKUP"
  | "FIGMA-BACKUP-GRAPHIC"
  | "MIRO-FILE-ICON"
  | "MIRO-BACKUP-GRAPHIC"
  | "MAGICUL-COMMENTS"
  | "MIRO"
  | "MURAL"
  | "MIRO-BACKUP"
  | "DESIGN-FILE"
  | "DESIGN-FILE-GRAPHIC"
  | "CHEVRON"
  | "TOOL-MENU"
  | "PARTIAL-ICON"
  | "MINUS-ICON"
  | "DEVELOPMENT-ICON"
  | "DISCONNECT-ICON"
  | "AGENCY-ICON"
  | "DATA-SECUTIRY"
  | "TEAM-COLLABORATION"
  | "FILE-MIGRATION"
  | "FINGER-PRINT"
  | "ENCRYPTION"
  | "HOSTING"
  | "SUPPORT"
  | "CMS-ICON"
  | "CMS-ICON-LIGHT"
  | "WEBFLOW"
  | "FIGJAM"
  | "NOTES"
  | "ARROW-BLACK"
  | "TEXT-ICON"
  | "GRADIENT-ICON"
  | "BUBBLE"
  | "WEBSITE-ICON"
  | "AXURE-ICON"
  | "EXPRESS-ICON"
  | "AFTER-EFFECTS-ICON"
  | "JPG-ICON"
  | "XD-ICON"
  | "MAGICUL-ICON"
  | "MAGICUL-X"
  | "EXCLAIMATION-ICON"
  | "INSTAGRAM-ICON"
  | "NEW-PNG-FILE-ICON"
  | "NEW-JPG-FILE-ICON"
  | "NEW-PDF-FILE-ICON"
  | "NEW-LOCK-ICON"
  | "SETTINGS-ICON"
  | "LUCIDCHART"
  | "FREEHAND-ICON"
  | "EXTERNAL"
  | "HTML_ICON"
  | "WORDPRESS_ICON"
  | "VISIO_ICON";

export const iconsMap: Record<IIconType, string> = {
  EYE: "eye",
  "EYE-VISIBLE": "eye-visible",
  "EYE-INVISIBLE": "eye-invisible",
  PIGGY: "piggy-1",
  REFRESH: "refresh",
  BOX: "box",
  SHIELD: "shield",
  LOCK: "lock",
  "FILE-SUPPORT": "FileSupportIcon",
  BOLT: "bolt-icon",
  "STAR-1": "star",
  "STAR-2": "star-2",
  "STAR-3": "star-3",
  ADOBEXD: "adobeXD",
  FIGMA: "figma",
  SKETCH: "sketch",
  CANVA: "canva",
  FACEBOOK: "facebook",
  TWITTER: "twitter",
  YOUTUBE: "youtube",
  "ARROW-RIGHT": "arrow-right",
  "ARROW-RIGHT-GREY": "arrow-right-grey",
  "ARROW-RIGHT-SMALL": "arrow-right-small",
  "ARROW-DOWN": "arrow-down",
  SHEETS: "sheets",
  TV: "tv",
  "REFRESH-2": "refresh-2",
  ADOBEXD_FILE: "xd-file-icon",
  PLUS2: "plus2",
  INFO: "info",
  "INFO-EMPTY": "info-empty",
  "STAR-4": "star-4",
  SKETCH_FILE: "sketch-file-icon",
  FIGMA_FILE: "figma-file-icon",
  FIGJAM_FILE: "figjam-file-icon",
  AI_FILE: "ai-file-icon",
  PSD_FILE: "psd-file-icon",
  PDF_FILE: "pdf-file-icon",
  CLOSE: "close",
  "CONVERTER-ICON": "converter-icon",
  "SHIELD-2": "shield-icon",
  "LOCK-2": "lock-icon",
  PLUGIN: "plugin-icon",
  "CHECK-ICON": "check-icon",
  "CHECK-ICON-EMPTY": "check-icon-empty",
  "ERROR-ICON": "error-icon",
  "SCROLL-DOWN-ICON": "scroll-down-icon",
  "IMAGE-ICON": "image-icon",
  "IMAGE-RED-ICON": "image-red-icon",
  "FOLDER-ICON": "folder-icon",
  "FOLDER-SIMPLE-ICON": "folder-simple-icon",
  "PEN-ICON": "pen-icon",
  "TIP-ARROW-ICON": "tip-arrow-icon",
  "TIP-ARROW-ICON-BIG": "tip-arrow-icon-big",
  "QUOTES-ICON": "quotes-icon",
  "CHAT-ICON": "chat",
  CHECK_2: "check",
  "FLOW-ICON": "flow-icon",
  "SYNC-ICON": "sync-icon",
  "SWITCH-ICON": "switch-icon",
  "TOGETHER-ICON": "together-icon",
  "COMPANY-ICON": "company-icon",
  "SHARE-ICON": "share-icon",
  "SPREAD-ICON": "spread-icon",
  "DESIGN-TOOL-ICON": "design-tool-icon",
  "LOCK-DOT-ICON": "lock-dot-icon",
  "INTEGRATION-ICON": "integration-icon",
  "SYNC-RED-ICON": "sync-red-icon",
  "TIME-ICON": "time-icon",
  "TIME-BLUE-ICON": "time-blue-icon",
  "CHECK-GREEN-ICON": "check-green-icon",
  MEDIUM: "medium-icon",
  "ARROW-WHITE": "arrow-white",
  "FILE-NOT-FOUND-ICON": "file-not-found-icon",
  "CONNECT-FIGMA-ICON": "connect-figma",
  SLACK: "slack-icon",
  "SPINNER-ICON": "spinner-icon",
  PHOTOSHOP: "photoshop-icon",
  AI: "ai-icon",
  PDF: "pdf-icon",
  OWL: "owl",
  OVAL: "oval-copy-10",
  OPEN_EXTERNAL: "open-external",
  "ENTERPRISE-ICON": "enterprise-icon",
  "HELP-ICON": "help-icon",
  "PRIVACY-ICON": "privacy-icon",
  "DEMO-ICON": "demo-icon",
  "CONTACT-ICON": "contact-icon",
  "PLAY-ICON-BIG": "play-icon-big",
  "DOWNLOAD-ICON": "download-icon",
  "BLOG-ICON": "blog-icon",
  "NO-PREVIEW-ICON": "no-preview",
  "GOOGLE-SLIDES": "google-slides",
  "GOOGLE-SLIDES-FILE-ICON": "google-slides-file-icon",
  KEYNOTE: "keynote",
  "SQUARE-ICON": "square-icon",
  "T-ICON": "t-icon",
  "CORRUPTED-ICON": "corrupted-icon",
  "OUTDATED-ICON": "outdated-icon",
  EPS_FILE: "eps-icon",
  "BACKUP-ICON": "backup-icon",
  "GLOBE-ICON": "globe-icon",
  USER: "user-icon",
  "USER-DARK": "user-dark",
  LETTER: "letter-icon",
  LOGOUT: "logout-icon",
  "CLOSE-CIRCLED-ICON": "close-circled",
  "PLUS-CIRCLED-ICON": "plus-circled",
  "RHOMB-ICON": "rhomb-icon",
  "ARROW-POINTER-ICON": "arrow-pointer",
  "BALANCE-ICON": "balance-icon",
  "COMPONENTS-ICON": "components-icon",
  "FREE-ICON": "free-icon",
  "FILE-ICON": "file-icon",
  "TRIANGLE-UP": "triangle-up",
  "TRIANGLE-DOWN": "triangle-down",
  "SHIELD-ALERT": "shield-alert",
  WARNING: "warning-icon",
  INDESIGN: "indesign",
  TRASH: "trash",
  GEAR: "gear",
  "CHECK-CIRCLED-ICON": "check-circled-icon",
  POWERPOINT: "powerpoint",
  "POWERPOINT-FILE-ICON": "powerpoint-file-icon",
  "MIRO-FILE-ICON": "miro-file-icon",
  CLOCK: "clock",
  "FILL-ICON": "fill-icon",
  "CANVA-FILE-ICON": "canva-file-icon",
  "INDESIGN-FILE-ICON": "indesign-file-icon",
  "PARTIAL-ICON": "partial",
  "MINUS-ICON": "minus",
  "DEVELOPMENT-ICON": "development",
  "DISCONNECT-ICON": "disconnect",
  "FIGMA-BACKUP": "figma-backup",
  "AIRTABLE-BACKUP": "airtable-backup",
  "FIGMA-BACKUP-GRAPHIC": "figma-backup-graphic",
  "MIRO-BACKUP": "miro-backup",
  "MIRO-BACKUP-GRAPHIC": "miro-backup-graphic",
  "MAGICUL-COMMENTS": "magicul-comments",
  "DESIGN-FILE": "design-file",
  "DESIGN-FILE-GRAPHIC": "design-file-graphic",
  CHEVRON: "chevron",
  "TOOL-MENU": "tool-menu",
  "AGENCY-ICON": "agency",
  "DATA-SECUTIRY": "data-security",
  "TEAM-COLLABORATION": "team-collaboration",
  "FILE-MIGRATION": "file-migration",
  "FINGER-PRINT": "finger-print",
  ENCRYPTION: "encryption",
  HOSTING: "hosting",
  SUPPORT: "support",
  "CMS-ICON": "cms-icon",
  "CMS-ICON-LIGHT": "cms-icon-light",
  WEBFLOW: "webflow-icon",
  "CHECK-CIRCLED-EMPTY": "check-circled-empty",
  MIRO: "miro-icon",
  FIGJAM: "figjam-icon",
  NOTES: "notes-icon",
  "ARROW-BLACK": "arrow-black",
  "TEXT-ICON": "text-icon",
  "GRADIENT-ICON": "gradient-icon",
  BUBBLE: "bubble-icon",
  "WEBSITE-ICON": "website-icon",
  "AXURE-ICON": "axure-icon",
  "EXPRESS-ICON": "express",
  "AFTER-EFFECTS-ICON": "after-effects",
  "JPG-ICON": "jpg",
  "XD-ICON": "adobe-xd",
  "MAGICUL-ICON": "magicul",
  "MAGICUL-X": "magicul-x",
  "EXCLAIMATION-ICON": "exclamation-mark",
  "INSTAGRAM-ICON": "instagram-icon",
  "NEW-PNG-FILE-ICON": "new-png-file",
  "NEW-JPG-FILE-ICON": "new-jpg-file",
  "NEW-PDF-FILE-ICON": "new-pdf-file",
  "NEW-LOCK-ICON": "new-lock",
  "SETTINGS-ICON": "settings",
  "FREEHAND-ICON": "freehand",
  LUCIDCHART: "lucidchart",
  MURAL: "mural",
  EXTERNAL: "external",
  HTML_ICON: "html-icon",
  WORDPRESS_ICON: "wordpress-icon",
  VISIO_ICON: "visio-icon",
};

export const getIcon = async (
  name: IIconType,
): Promise<React.ReactSVGElement> => {
  return (await import(`@/icons/${iconsMap[name]}.svg`)).default;
};
