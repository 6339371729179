import React from "react";
import NextLink from "next/link";

import { IWithCustomStyle } from "@/hooks/useTheme/types";
import { getIsLinkExternal } from "@/utils/getIsLinkExternal";

import { A } from "../A";
import { IA } from "../A/types";

type IProps = IA &
  JSX.IntrinsicElements["a"] &
  IWithCustomStyle & {
    href: string;
    currentPath?: string;
    locale?: string;
    passHref?: boolean;
  };

export const Link: React.FC<IProps> = ({
  href,
  currentPath,
  children,
  ref,
  locale,
  passHref,
  ...rest
}) => {
  const isLinkExternal = getIsLinkExternal(href);

  if (isLinkExternal || currentPath === href) {
    return (
      <A href={href} {...rest}>
        {children}
      </A>
    );
  }

  return (
    <NextLink href={href} passHref={true} locale={locale} prefetch={false}>
      <A {...rest}>{children}</A>
    </NextLink>
  );
};
