import React from "react";
import { useFela, CssFelaStyle } from "react-fela";
import useTranslation from "next-translate/useTranslation";

import { Grid } from "@/components/Grid";
import { useTheme } from "@/hooks/useTheme";
import { Heading } from "@/components/Heading";

import { content } from "./content";
import { Socials } from "./Socials";
import { Links } from "./Links";
import { CompanyInfo } from "./CompanyInfo";
import { LanguageSwitcher } from "../LanguageSwitcher";

export const Footer: React.FC = () => {
  const { css } = useFela();
  const { colors, bp } = useTheme();
  const { t } = useTranslation();
  const headingStyle: CssFelaStyle<unknown, unknown> = {
    marginBottom: 24,
    fontSize: 16,
    lineHeight: 1.5,
    color: colors.WHITE,
    filter: "blur(0px)",
    opacity: 0.6,
  };
  return (
    <footer>
      <Grid
        wrapperStyle={{
          backgroundImage: `linear-gradient(143deg, ${colors.PURLPE} -53%, ${colors.GRADIENT_BLUE} 58%)`,
        }}
        customStyle={{
          position: "relative",
          color: colors.WHITE,
          flexDirection: "column",
          justifyContent: "space-between",
          [bp.FROM_TABLET]: {
            padding: "50px 20px",
          },
          [bp.FROM_DESKTOP]: {
            padding: "50px 20px",
          },
        }}
      >
        <div
          className={css({
            display: "flex",
            flexDirection: "column-reverse",
            [bp.FROM_DESKTOP]: { flexDirection: "row" },
          })}
        >
          <div
            className={css({
              flex: 1,
              flexWrap: "wrap",
              display: "flex",
              justifyContent: "space-between",
            })}
          >
            <div className={css({ minWidth: 215 })}>
              <CompanyInfo />
              <Socials socials={content.socials} />
            </div>

            <div className={css({ minWidth: 215 })}>
              <Heading as="h4" customStyle={headingStyle}>
                {t("footer:menu.title")}
              </Heading>

              <Links links={content.menu} namespace="menu" />
            </div>

            <div className={css({ minWidth: 215 })}>
              <Heading as="h4" customStyle={headingStyle}>
                {t("footer:guides.title")}
              </Heading>

              <Links
                links={content.guides}
                namespace="guides"
                icon="ARROW-WHITE"
              />
            </div>
          </div>
        </div>

        <p
          className={css({
            letterSpacing: 0.5,
            marginTop: 21,
          })}
        >
          {t("footer:copyright")}
        </p>
        <LanguageSwitcher
          languages={[
            { id: 0, locale: "en", value: "English" },
            { id: 1, locale: "fr", value: "Français" },
            { id: 2, locale: "de", value: "Deutsch" },
            { id: 3, locale: "zh", value: "简体中文" },
            { id: 4, locale: "es", value: "Español" },
          ]}
        />
      </Grid>
    </footer>
  );
};
