import React from "react";
import { useFela } from "react-fela";

import { ButtonProps } from "./types";
import { getButtonStyles } from "./helpers/getButtonStyles";

export const Button: React.FC<ButtonProps> = ({
  variant = "PRIMARY",
  children,
  customStyle = {},
  disabled,
  ...rest
}) => {
  const { css } = useFela();
  const styles = getButtonStyles(variant, disabled);

  return (
    <button type="button" className={css(styles, customStyle)} {...rest}>
      {children}
    </button>
  );
};
