import React from "react";
import axios from "axios";

import { BACKEND_ENDPOINTS } from "@/constants/backend";
import { IUser, IAuth } from "@/hooks/useAuth/types";
import { createPopup } from "@/utils/createPopup";

export const getProfile = (): Promise<IUser> => {
  return axios
    .get(BACKEND_ENDPOINTS.getProfile)
    .then(res => res.data)
    .catch(() => {
      // handle error somehow?
    });
};

export const login = (
  setAuthState: React.Dispatch<React.SetStateAction<IAuth>>,
): Promise<void> => {
  const externalWindow = createPopup({
    url: BACKEND_ENDPOINTS.loginPopup(window.location.origin),
    title: "Login to xd2sketch",
    width: 500,
    height: 700,
  });

  if (externalWindow === null) {
    throw new Error("Couldn't open login window");
  }

  return new Promise(resolve => {
    const receiveMessage = (event: MessageEvent<{ status: string }>) => {
      if (
        !event.data ||
        !event.data.status ||
        event.data.status !== "LOGGED_IN"
      ) {
        return;
      }

      window.removeEventListener("message", receiveMessage);
      externalWindow.close();

      getProfile().then(profile => {
        if (profile) {
          setAuthState(prevState => ({
            ...prevState,
            user: profile,
            loggedIn: true,
            loading: true,
          }));

          resolve();
        }
      });
    };

    window.addEventListener("message", receiveMessage);
  });
};

export const logout = (
  setAuthState: React.Dispatch<React.SetStateAction<IAuth>>,
) => {
  return axios.get(BACKEND_ENDPOINTS.logout).catch(() => {
    setAuthState(prevState => ({
      ...prevState,
      user: null,
      loggedIn: false,
    }));
  });
};
