import React from "react";
import { useFela, CssFelaStyle } from "react-fela";

import { useTheme, IColor, IWithCustomStyle } from "@/hooks/useTheme";

interface IGrid {
  background?: IColor;
  wrapperStyle?: CssFelaStyle<{}, {}>;
}

export const Grid: React.FC<
  IGrid & IWithCustomStyle & JSX.IntrinsicElements["div"]
> = ({
  background = "WHITE",
  customStyle = {},
  wrapperStyle = {},
  id,
  ...rest
}) => {
  const { css } = useFela();
  const { colors, bp } = useTheme();

  return (
    <section
      className={css(
        {
          width: "100%",
          backgroundColor: colors[background],
        },
        wrapperStyle,
      )}
      id={id}
    >
      <div
        className={css(
          {
            padding: "50px 20px",
            maxWidth: 1240,
            margin: "0 auto",
            display: "flex",
            [bp.FROM_TABLET]: {
              padding: "70px 20px",
            },
            [bp.FROM_DESKTOP]: {
              padding: "80px 20px",
            },
          },
          customStyle,
        )}
        {...rest}
      />
    </section>
  );
};
