import React, { FC, ReactNode, useState, useEffect } from "react";
import AnimateHeight, { AnimateHeightProps } from "react-animate-height";

interface ISeoFriendlyAnimateHeight {
  children: ReactNode;
}

const SeoFriendlyAnimateHeight: FC<
  ISeoFriendlyAnimateHeight & AnimateHeightProps
> = ({ children, ...rest }) => {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return <div>{children}</div>;
  }

  return <AnimateHeight {...rest}>{children}</AnimateHeight>;
};

export default SeoFriendlyAnimateHeight;
