import React, { FC, ReactSVGElement, useEffect, useState } from "react";
import { useFela, CssFelaStyle } from "react-fela";

import { IIconType, getIcon } from "@/icons";
import { IWithCustomStyle } from "@/hooks/useTheme/types";

interface IIcon {
  iconType: IIconType;
}

interface IStyledIconWrapper {
  height?: number;
  width?: number;
  wrapperStyle?: CssFelaStyle<{}, {}>;
}

export const Icon: FC<IIcon> = ({ iconType }) => {
  const [IconComponent, setIcon] = useState<ReactSVGElement>();

  useEffect(() => {
    let isMounted = true;

    getIcon(iconType).then(icon => {
      if (isMounted) setIcon(icon);
    });

    return () => {
      isMounted = false;
      setIcon(undefined);
    };
  }, [iconType]);

  return IconComponent || null;
};

export const StyledIconWrapper: React.FC<
  IWithCustomStyle & IStyledIconWrapper
> = ({
  height,
  width,
  customStyle = {},
  wrapperStyle = {},
  children,
  ...rest
}) => {
  const { css } = useFela();

  const commonStyles = { height, width };
  return (
    <div
      className={css(
        {
          nested: {
            "> svg": {
              ...commonStyles,
              ...customStyle,
            },
          },
        },
        commonStyles,
        wrapperStyle,
      )}
      {...rest}
    >
      {children}
    </div>
  );
};
