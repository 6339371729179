import React from "react";

import { FormSourceType, InquiryForm } from "@/containers/InquiryForm";
import { Modal } from "@/components/Modal";
import { useTheme } from "@/hooks/useTheme";

interface IContext {
  isInquiryFormVisible: boolean;
  toggleInquiryForm: (sourceId: FormSourceType, isOpen?: boolean) => void;
  sourceId?: FormSourceType;
}

const initialContext: IContext = {
  isInquiryFormVisible: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleInquiryForm: () => {},
  sourceId: undefined,
};

const InquiryFormContext = React.createContext<IContext>(initialContext);

export const useInquiryForm = () => {
  const ctx = React.useContext(InquiryFormContext);

  if (!ctx) {
    throw new Error(
      "useInquiryForm must be used within a InquiryFormContextProvider",
    );
  }
  return ctx;
};

export const InquiryFormContextProvider: React.FC = ({ children }) => {
  const { bp } = useTheme();

  const [state, setState] = React.useState<IContext>(initialContext);
  const toggleInquiryForm = (sourceId?: FormSourceType, isOpen?: boolean) => {
    setState(prevState => ({
      ...prevState,
      isInquiryFormVisible:
        typeof isOpen === "boolean" ? isOpen : !state.isInquiryFormVisible,
      sourceId,
    }));
  };

  const contextValue: IContext = {
    ...state,
    toggleInquiryForm,
  };

  return (
    <InquiryFormContext.Provider value={contextValue}>
      {children}

      {state.isInquiryFormVisible && (
        <Modal
          id="inquiry-modal"
          onClose={() => toggleInquiryForm(state.sourceId, false)}
          customStyle={{
            height: "100%",
            maxHeight: "95vh",
            [bp.FROM_DESKTOP]: {
              minWidth: 1000,
              width: "50vw",
            },
          }}
        >
          <InquiryForm sourceId={state.sourceId} />
        </Modal>
      )}
    </InquiryFormContext.Provider>
  );
};
