import { IMoreDropdownGroup } from "./types";

interface ISolutionsDropdownContent {
  groups: IMoreDropdownGroup[];
}

export const content: ISolutionsDropdownContent = {
  groups: [
    {
      title: "enterprise",
      icon: "ENTERPRISE-ICON",
      href: "/solutions/for-enterprise",
    },
    { title: "agency", icon: "AGENCY-ICON", href: "/solutions/for-agencies" },
  ],
};
