import React from "react";
import { CssFelaStyle, useFela } from "react-fela";

import { IWithCustomStyle } from "@/hooks/useTheme";

interface ILazyImage {
  rootMargin?: string;
  wrapperStyle?: CssFelaStyle<{}, {}>;
}

type IProps = ILazyImage & JSX.IntrinsicElements["img"] & IWithCustomStyle;

export const Image: React.FC<IProps> = ({
  alt,
  wrapperStyle = {},
  customStyle = {},
  rootMargin = "400px",
  loading = "lazy",
  ...rest
}) => {
  const { css } = useFela();

  return (
    <div
      className={css({
        display: "flex",
        alignItems: "flex-start",
        ...wrapperStyle,
      })}
    >
      <img alt={alt} className={css(customStyle)} loading={loading} {...rest} />
    </div>
  );
};
