import { ITheme, IBrakepoints, IColors, IFonts } from "./types";

const colors: IColors = {
  BLACK: "#000000",
  WHITE: "#FFFFFF",
  PURLPE: "#7427EF",
  BLUE: "#2B2BA1",
  SKY_BLUE: "#37C2DA",
  LIGHT_BLUE: "#FAFAFF",
  ORANGE: "#FF6F02",
  PALE_PURLPE: "#6179FF",
  SUNNY: "#FFC264",
  STARDUST: "#DFDFDF",
  GRADIENT_BLUE_DARK: "#1B0068",
  NAVY: "#120044",
  GRADIENT_BLUE: "#240676",
  GRADIENT_PURPLE: "#6D3CEA",
  DARK_GREY: "#47505A",
  DARK_GREY1: "#13283F",
  GRAY: "#6C737B",
  LIGHT_GRAY: "#A2A7AC",
  LIGHT_GRAY1: "#fbfaff",
  LIGHT_GRAY2: "#f9f9f9",
  LIGHT_GRAY3: "#ced2d8",
  RED: "#FF5A60",
  GHOST_WHITE: "#FBFBFF",
  GHOST_WHITE1: "#f8f8fa",
  CHARCOAL: "#242628",
  TRANSPARENT: "transparent",
  YELLOW: "#FFBE3E",
  BRIGHT_GRAY: "#EFEFEF",
  DANGER_RED: `rgba(255, 90, 96, 0.1)`,
  LIGHT_RED: ` rgba(255,90,96,0.05)`,
  CETACEAN_BLUE: "rgba(16, 5, 48, 0.18)",
  MAASTRICHT_BLUE: "#081a39",
  CULTURED: "#F5F5F5",
};

const fonts: IFonts = {
  PRIMARY_FONT: "Nunito Sans",
};

const breakpoints: IBrakepoints = {
  FROM_LARGE_MOBILE: "@media (min-width: 412px)",
  FROM_TABLET: "@media (min-width: 768px)",
  FROM_DESKTOP: "@media (min-width: 992px)",
  FROM_LARGE_DESKTOP: "@media (min-width: 1280px)",
  ONLY_SMALL_MOBILE: "@media (min-width: 0px) and (max-width:360px)",
  ONLY_MOBILE: "@media (min-width: 0px) and (max-width:767.5px)",
  ONLY_TABLET: "@media (min-width: 768px) and (max-width: 991.5px)",
  TO_DESKTOP: "@media (max-width: 991.5px)",
};

export const theme: ITheme = {
  colors,
  bp: breakpoints,
  fonts,
};
