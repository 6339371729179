import React from "react";
import { useFela } from "react-fela";

export const CompanyInfo: React.FC = () => {
  const { css } = useFela();

  return (
    <>
      <h2 className={css({ fontSize: 28, margin: 0 })}>Magicul</h2>

      <p
        className={css({
          margin: "16px 0",
          letterSpacing: 0.5,
          nested: {
            "> span": {
              display: "block",
            },
          },
        })}
      >
        <span>by Magicul</span>
      </p>
    </>
  );
};
