import * as Sentry from "@sentry/node";
import Axios from "axios";

import { BACKEND_ENDPOINTS } from "@/constants/backend";
import { ISalesInquiryFormData } from "@/containers/InquiryForm/types";

export const sendSalesInquiryForm = (params: ISalesInquiryFormData) => {
  const formData = new FormData();

  Object.entries(params).forEach(([key, value]) => {
    if (typeof value !== "string") {
      formData.append(key, value!.toString());
    } else {
      formData.append(key, value);
    }
  });

  return Axios.post(BACKEND_ENDPOINTS.salesInquiryForm, formData, {
    withCredentials: false,
  }).catch(error => {
    Sentry.withScope(scope => {
      scope.setExtras(params);
      scope.setTag("where", "Services: salesInquiry");
      Sentry.captureException(error);
    });
  });
};
