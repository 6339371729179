import { IConverterDropdownGroup } from "./types";

export const dropdownElements: IConverterDropdownGroup[] = [
  {
    sourceFileType: "xd",
    icon: "ADOBEXD",
    targetFileTypes: [
      { targetFileType: "sketch" },
      { targetFileType: "figma" },
      { targetFileType: "psd" },
      { targetFileType: "ai" },
      { targetFileType: "pdf" },
      { targetFileType: "jpg" },
      { targetFileType: "powerpoint" },
      { targetFileType: "canva" },
    ],
  },
  {
    sourceFileType: "sketch",
    icon: "SKETCH",
    targetFileTypes: [
      { targetFileType: "xd" },
      { targetFileType: "figma" },
      { targetFileType: "psd" },
      { targetFileType: "ai" },
    ],
  },
  {
    sourceFileType: "pdf",
    icon: "PDF",
    targetFileTypes: [
      { targetFileType: "figma" },
      { targetFileType: "xd" },
      { targetFileType: "psd" },
      { targetFileType: "sketch" },
      { targetFileType: "ai" },
      { targetFileType: "indd" },
    ],
  },
  {
    sourceFileType: "googleslides",
    icon: "GOOGLE-SLIDES",
    targetFileTypes: [{ targetFileType: "figma" }, { targetFileType: "canva" }],
  },
  {
    sourceFileType: "ai",
    icon: "AI",
    targetFileTypes: [
      { targetFileType: "figma" },
      { targetFileType: "sketch" },
    ],
  },
  {
    sourceFileType: "indd",
    icon: "INDESIGN",
    targetFileTypes: [
      { targetFileType: "figma" },
      { targetFileType: "xd" },
      { targetFileType: "sketch" },
    ],
  },
  {
    sourceFileType: "figma",
    icon: "FIGMA",
    targetFileTypes: [
      { targetFileType: "sketch" },
      { targetFileType: "xd" },
      { targetFileType: "psd" },
      { targetFileType: "ai" },
      { targetFileType: "canva" },
      { targetFileType: "powerpoint" },
      { targetFileType: "googleslides" },
      { targetFileType: "webflow" },
      { targetFileType: "bubble" },
      { targetFileType: "axure" },
      { targetFileType: "aftereffects" },
      { targetFileType: "pdf" },
      { targetFileType: "indd" },
    ],
  },
  {
    sourceFileType: "eps",
    icon: "EPS_FILE",
    targetFileTypes: [{ targetFileType: "figma" }],
  },
  {
    sourceFileType: "canva",
    icon: "CANVA",
    targetFileTypes: [
      { targetFileType: "figma" },
      { targetFileType: "powerpoint" },
      { targetFileType: "googleslides" },
      { targetFileType: "psd" },
      { targetFileType: "ai" },
      { targetFileType: "indd" },
      { targetFileType: "html" },
      { targetFileType: "wordpress" },
      { targetFileType: "xd" },
    ],
  },
  {
    sourceFileType: "psd",
    icon: "PHOTOSHOP",
    targetFileTypes: [
      { targetFileType: "sketch" },
      { targetFileType: "figma" },
      { targetFileType: "canva" },
    ],
  },
  {
    sourceFileType: "freehand",
    icon: "FREEHAND-ICON",
    targetFileTypes: [{ targetFileType: "figjam" }],
  },
  {
    sourceFileType: "miro",
    icon: "MIRO",
    targetFileTypes: [
      { targetFileType: "figjam" },
      { targetFileType: "lucidchart" },
      { targetFileType: "mural" },
    ],
  },
  {
    sourceFileType: "mural",
    icon: "MURAL",
    targetFileTypes: [{ targetFileType: "miro" }],
  },
  {
    sourceFileType: "lucidchart",
    icon: "LUCIDCHART",
    targetFileTypes: [{ targetFileType: "miro" }, { targetFileType: "figjam" }],
  },
  {
    sourceFileType: "powerpoint",
    icon: "POWERPOINT",
    targetFileTypes: [
      { targetFileType: "figma" },
      { targetFileType: "sketch" },
      { targetFileType: "canva" },
      { targetFileType: "xd" },
      { targetFileType: "psd" },
      { targetFileType: "ai" },
    ],
  },
  {
    sourceFileType: "figjam",
    icon: "FIGJAM",
    targetFileTypes: [
      { targetFileType: "miro" },
      { targetFileType: "lucidchart" },
    ],
  },
  {
    sourceFileType: "website",
    icon: "WEBSITE-ICON",
    targetFileTypes: [{ targetFileType: "figma" }],
  },
  {
    sourceFileType: "express",
    icon: "EXPRESS-ICON",
    targetFileTypes: [{ targetFileType: "figma" }, { targetFileType: "canva" }],
  },
  {
    sourceFileType: "keynote",
    icon: "KEYNOTE",
    targetFileTypes: [
      { targetFileType: "figma" },
      { targetFileType: "xd" },
      { targetFileType: "sketch" },
    ],
  },
  {
    sourceFileType: "visio",
    icon: "VISIO_ICON",
    targetFileTypes: [{ targetFileType: "figjam" }],
  },
];
