import React, { useState } from "react";
import { useFela, CssFelaStyle } from "react-fela";
import { IIconType } from "@/icons";
import { IWithCustomStyle, useTheme } from "@/hooks/useTheme";
import { StyledIconWrapper, Icon } from "../Icon";

interface IDropdown {
  title?: string | React.ReactNode;
  titleIcon?: IIconType | null;
  showDropdownIcon?: boolean;
  dropdownIconColor?: "light" | "dark";
  showCloseButton?: boolean;
  contentCss?: CssFelaStyle<{}, {}>;
  iconStyles?: CssFelaStyle<{}, {}>;
  children: JSX.Element;
}

export interface IDropdownChildren {
  onClose?: () => void;
  visible?: boolean;
}

export const Dropdown: React.FC<IDropdown & IWithCustomStyle> = ({
  title,
  titleIcon = null,
  showDropdownIcon = true,
  showCloseButton = true,
  dropdownIconColor = "dark",
  children,
  customStyle = {},
  contentCss = {},
  iconStyles = {},
}) => {
  const { css } = useFela();
  const { bp, colors } = useTheme();
  const [isVisible, setIsVisible] = useState(false);

  const duration = ".15s";
  const delay = isVisible ? "0s" : duration;

  const open = () => {
    setIsVisible(true);
  };

  const close = () => {
    setIsVisible(false);
  };

  return (
    <div
      onMouseEnter={open}
      onMouseLeave={close}
      onTouchStart={open}
      tabIndex={0}
    >
      <div
        className={css({
          display: "flex",
          alignItems: "center",
        })}
      >
        {titleIcon && (
          <StyledIconWrapper wrapperStyle={iconStyles}>
            <Icon iconType={titleIcon} />
          </StyledIconWrapper>
        )}
        {title && (
          <span
            className={css(
              {
                padding: 0,
                margin: 0,
                fontSize: 14,
                textShadow: isVisible
                  ? `0 0 .01px ${colors.DARK_GREY}, 0 0 .01px ${colors.DARK_GREY}`
                  : "none",
                border: "none",
                outline: "none",
                background: "none",
                cursor: "pointer",
                display: "inline-block",
                letterSpacing: 0.5,
                textDecoration: "none",
                position: "relative",
              },
              customStyle,
            )}
          >
            {title}
          </span>
        )}

        {showDropdownIcon && (
          <StyledIconWrapper
            wrapperStyle={{ marginLeft: 8 }}
            customStyle={{
              transform: isVisible ? "rotateX(180deg)" : "rotateX(0deg)",
              nested: {
                "& > path": {
                  fill: colors.WHITE,
                  [bp.FROM_DESKTOP]: {
                    fill:
                      dropdownIconColor === "dark"
                        ? colors.DARK_GREY
                        : colors.WHITE,
                  },
                },
              },
            }}
          >
            <Icon iconType="CHEVRON" />
          </StyledIconWrapper>
        )}
      </div>

      {isVisible && (
        <div
          className={css({
            top: 0,
            width: 80,
            height: 60,
            marginLeft: -25,
            position: "absolute",
          })}
        />
      )}

      <div
        className={css(
          {
            zIndex: 200,
            cursor: "auto",
            position: "absolute",
            opacity: isVisible ? 1 : 0,
            visibility: isVisible ? "visible" : "hidden",
            transition: `opacity ${duration}, visibility linear 0s ${delay}`,
          },
          contentCss,
        )}
      >
        {showCloseButton && (
          <button
            onClick={close}
            type="button"
            className={css({
              border: "none",
              background: "none",
              position: "fixed",
              top: 10,
              right: 6,
              zIndex: 10,
              padding: 10,
              [bp.FROM_TABLET]: {
                display: "none",
              },
            })}
          >
            <StyledIconWrapper
              customStyle={{
                height: 14,
                width: 14,
              }}
            >
              <Icon iconType="CLOSE" />
            </StyledIconWrapper>
          </button>
        )}
        <div
          className={css({
            display: isVisible ? "block" : "none",
          })}
        >
          {React.cloneElement(children, {
            visible: isVisible,
          })}
        </div>
      </div>
    </div>
  );
};
