import React from "react";
import { useFela } from "react-fela";
import useTranslation from "next-translate/useTranslation";

import { useTheme } from "@/hooks/useTheme";
import { Icon, StyledIconWrapper } from "@/components/Icon";

import { ISocialLink } from "../types";

interface IProps {
  socials: ISocialLink[];
}

export const Socials: React.FC<IProps> = ({ socials }) => {
  const { css } = useFela();
  const { colors, bp } = useTheme();
  const { t } = useTranslation();

  if (!socials) {
    return null;
  }

  return (
    <div
      className={css({
        marginTop: 30,
        display: "flex",
        flexDirection: "column",
        [bp.ONLY_MOBILE]: {
          marginBottom: 20,
        },
        [bp.FROM_TABLET]: {
          alignItems: "center",
          flexDirection: "row",
        },
      })}
    >
      <p className={css({ letterSpacing: 0.5, marginRight: 32 })}>
        <strong>{t("footer:followUs")}</strong>
      </p>

      <ul
        className={css({
          margin: 0,
          padding: 0,
          height: 16,
          display: "flex",
          listStyleType: "none",
          [bp.ONLY_MOBILE]: { marginTop: 12 },
        })}
      >
        {socials.map((social, index) => (
          <li
            key={`FooterSocial-${index}-${social.title}`}
            className={css({
              display: "flex",
              alignItems: "center",
              nested: {
                ":not(:first-of-type)": {
                  marginLeft: 32,
                },
              },
            })}
          >
            <a
              href={social.href}
              title={social.title}
              target="_blank"
              rel="noopener noreferrer"
              className={css({
                display: "block",
                cursor: "pointer",
                color: colors.WHITE,
                textDecoration: "none",
                nested: {
                  "> svg": {
                    height: 16,
                  },
                },
              })}
            >
              <StyledIconWrapper
                customStyle={{ width: 16 }}
                wrapperStyle={{ display: "flex" }}
              >
                <Icon iconType={social.iconType} />
              </StyledIconWrapper>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};
