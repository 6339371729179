import React from "react";
import { useFela } from "react-fela";

import { useTheme } from "@/hooks/useTheme";
import { Dropdown } from "@/components/Dropdown";
import { Card } from "@/components/Card";
import { useRouter } from "next/router";
import { Link } from "@/components/Link";

interface ILanguage {
  id: number;
  value: string;
  locale: string;
}

interface IProps {
  languages: ILanguage[];
}

export const LanguageSwitcher: React.FC<IProps> = ({ languages }) => {
  const { css } = useFela();
  const { colors, bp } = useTheme();
  const router = useRouter();
  const currentLang =
    languages.find(({ locale }) => locale === router.locale)?.value ||
    "English";

  return (
    <div
      className={css({
        display: "flex",
        alignItems: "center",
        position: "relative",
        cursor: "pointer",
        padding: "0 2px",
        borderBottom: "2px solid transparent",
        transition: "border-bottom-color .15s",
        margin: "20px auto 0",
        [bp.FROM_TABLET]: {
          position: "absolute",
          right: 85,
          bottom: 50,
        },
      })}
    >
      <Dropdown
        title={currentLang}
        titleIcon="GLOBE-ICON"
        customStyle={{
          margin: "0 0 0 10px",
        }}
        contentCss={{
          top: -228,
          right: 0,
          left: -46,
          [bp.FROM_TABLET]: {
            right: -20,
          },
          nested: {
            "&::before": {
              content: "''",
              position: "absolute",
              top: 200,
              width: "100%",
              height: 30,
            },
            "&::after": {
              content: "''",
              position: "absolute",
              top: 213,
              left: 135,
              width: 0,
              height: 0,
              borderStyle: "solid",
              borderWidth: "10px 10px 0 10px",
              borderColor: `${colors.WHITE} ${colors.TRANSPARENT} ${colors.TRANSPARENT} ${colors.TRANSPARENT}`,
            },
            "& button": {
              top: 0,
              right: "-21px",
              nested: {
                "& div": {
                  width: "auto",
                },
              },
            },
            "& div": {
              width: 171,
            },
            "& ul": {
              padding: 12,
              margin: 0,
            },
            "& ul > a": {
              fontWeight: "normal",
              padding: "6px 12px",
              color: colors.DARK_GREY,
              border: "none",
              nested: {
                ":hover": {
                  backgroundColor: colors.BRIGHT_GRAY,
                  borderRadius: 4,
                },
                ":not(:last-child)": {
                  marginBottom: 5,
                },
              },
            },
          },
        }}
        dropdownIconColor="light"
      >
        <Card
          customStyle={{
            flex: 1,
            padding: "0px",
            borderRadius: 8,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            backgroundColor: colors.WHITE,
            boxShadow: `20px 20px 80px ${colors.CETACEAN_BLUE}`,
            position: "absolute",
            top: 0,
            [bp.FROM_TABLET]: {
              width: "450px",
              maxWidth: "100%",
              padding: 0,
              position: "relative",
            },
          }}
        >
          <ul>
            {languages.map(({ id, value, locale }) => (
              <Link
                key={id}
                href={router.asPath}
                locale={locale}
                customStyle={{ display: "block", color: colors.BLACK }}
              >
                {value}
              </Link>
            ))}
          </ul>
        </Card>
      </Dropdown>
    </div>
  );
};
