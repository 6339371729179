import { IMoreDropdownGroup, IMoreDropdownButton } from "./types";

interface IMoreDropdownContent {
  groups: IMoreDropdownGroup[];
  buttons: IMoreDropdownButton[];
}

export const content: IMoreDropdownContent = {
  groups: [
    {
      title: "helpCenter",
      icon: "HELP-ICON",
      href: "https://help.magicul.io/en/",
      external: true,
    },
    {
      title: "blog",
      icon: "BLOG-ICON",
      href: "https://magicul.io/blog",
      external: true,
    },
  ],
  buttons: [],
};
