import React from "react";
import { useFela } from "react-fela";

import { IWithCustomStyle, useTheme } from "@/hooks/useTheme";

type IProps = JSX.IntrinsicElements["li"] & IWithCustomStyle;

export const NavbarLink: React.FC<IProps> = ({ children, customStyle }) => {
  const { css } = useFela();
  const { bp } = useTheme();

  return (
    <li
      className={css({
        cursor: "pointer",
        borderBottom: "2px solid transparent",
        transition: "border-bottom-color .15s",
        margin: "5px 10px",
        [bp.TO_DESKTOP]: {
          margin: "10px 10px",
        },
        ...customStyle,
      })}
    >
      {children}
    </li>
  );
};
