import React from "react";
import { useFela } from "react-fela";
import { IWithCustomStyle, useTheme } from "@/hooks/useTheme";

interface IProps {
  text: string | React.ReactNode;
}

export const ProductHuntBanner: React.FC<IWithCustomStyle & IProps> = ({
  text,
  customStyle,
}) => {
  const { css } = useFela();
  const { colors } = useTheme();

  return (
    <div
      className={css({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px 20px",
        textAlign: "center",
        fontSize: 13,
        lineHeight: "21px",
        letterSpacing: 0.3,
        color: colors.WHITE,
        backgroundColor: colors.NAVY,
        zIndex: 1,
        ...customStyle,
      })}
    >
      <p
        className={css({
          nested: {
            "> a": {
              color: colors.ORANGE,
            },
          },
        })}
      >
        {text}
      </p>
    </div>
  );
};
