import React from "react";

let savedHeight: string | null = null;
let savedBodyOverflow: string | null = null;
let savedHTMLOverflow: string | null = null;

const preventDefaultScroll = (event: TouchEvent) => {
  event.preventDefault();
};

const lock = () => {
  savedHeight = window.getComputedStyle(document.body).height;
  savedBodyOverflow = window.getComputedStyle(document.body).overflow;
  savedHTMLOverflow = window.getComputedStyle(document.documentElement)
    .overflow;

  document.body.style.height = "100vh";
  document.body.style.overflow = "hidden";
  document.documentElement.style.overflow = "initial";

  document.body.addEventListener("touchmove", preventDefaultScroll, {
    passive: false,
  });
};

const unlock = () => {
  document.body.style.height = savedHeight || "";
  document.body.style.overflow = savedBodyOverflow || "";
  document.documentElement.style.overflow = savedHTMLOverflow || "";

  savedHeight = null;
  savedBodyOverflow = null;
  savedHTMLOverflow = null;

  document.body.removeEventListener("touchmove", preventDefaultScroll);
};

export const useScrollLock = (enabled: boolean) => {
  React.useEffect(() => {
    enabled ? lock() : unlock();

    return () => {
      unlock();
    };
  }, [enabled]);
};
