import React from "react";
import { useFela, CssFelaStyle } from "react-fela";
import useTranslation from "next-translate/useTranslation";
import { Link } from "@/components/Link";

import { useTheme } from "@/hooks/useTheme";
import { Card } from "@/components/Card";
import { useAuth } from "@/hooks/useAuth";
import { Icon, StyledIconWrapper } from "@/components/Icon";
import { IDropdownChildren } from "@/components/Dropdown";

export const ProfileDropdown: React.FC<IDropdownChildren> = ({ onClose }) => {
  const { css } = useFela();
  const { colors, bp } = useTheme();
  const { t } = useTranslation();
  const { logout } = useAuth();

  const linkStyles: CssFelaStyle<unknown, unknown> = {
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    fontWeight: 700,
    color: colors.DARK_GREY,
    cursor: "pointer",
    padding: "10px 10px 10px 15px",
    borderRadius: 8,
    width: "100%",

    [bp.FROM_TABLET]: {
      nested: {
        ":hover": {
          background: colors.GHOST_WHITE1,
        },
      },
    },
  };

  return (
    <Card
      customStyle={{
        flex: 1,
        padding: "15px 10px !important",
        borderRadius: 8,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: colors.WHITE,
        boxShadow: `20px 20px 80px ${colors.CETACEAN_BLUE}`,
        position: "absolute",
        top: 0,

        [bp.FROM_TABLET]: {
          width: "450px",
          maxWidth: "100%",
          position: "relative",
          nested: {
            "&::before": {
              content: "''",
              position: "absolute",
              top: -10,
              right: 0,
              width: "50%",
              height: 12,
            },
          },
        },
      }}
    >
      <Link customStyle={linkStyles} href="/account" onClick={onClose}>
        <StyledIconWrapper
          width={20}
          height={20}
          wrapperStyle={{ marginRight: 20 }}
          customStyle={{ nested: { "> path": { stroke: colors.SKY_BLUE } } }}
        >
          <Icon iconType="USER" />
        </StyledIconWrapper>

        {t("navigation:account")}
      </Link>
      <Link customStyle={linkStyles} href="/settings" onClick={onClose}>
        <StyledIconWrapper
          width={20}
          height={20}
          wrapperStyle={{ marginRight: 20 }}
        >
          <Icon iconType="GEAR" />
        </StyledIconWrapper>

        {t("navigation:settings")}
      </Link>
      <span className={css(linkStyles)} onClick={logout} role="button">
        <StyledIconWrapper
          width={20}
          height={20}
          wrapperStyle={{ marginRight: 20 }}
        >
          <Icon iconType="LOGOUT" />
        </StyledIconWrapper>

        {t("navigation:logout")}
      </span>
    </Card>
  );
};
