import { IButtonVariant, IVariant } from "./types";

export const variantMap: Record<IButtonVariant, IVariant> = {
  PRIMARY: {
    color: "WHITE",
    backgroundColor: "ORANGE",
    borderColor: "ORANGE",
  },
  DISABLED: {
    color: "LIGHT_GRAY",
    backgroundColor: "WHITE",
    borderColor: "STARDUST",
  },
  OUTLINED: {
    color: "ORANGE",
    backgroundColor: "TRANSPARENT",
    borderColor: "ORANGE",
  },
  DANGER: {
    color: "RED",
    backgroundColor: "DANGER_RED",
    borderColor: "DANGER_RED",
  },
};
