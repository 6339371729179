import React, { useEffect, useState } from "react";
import { useFela } from "react-fela";

import { useTheme } from "@/hooks/useTheme";
import { Card } from "@/components/Card";
import { IDropdownChildren } from "@/components/Dropdown";
import { Heading } from "@/components/Heading";
import { dropdownElements } from "@/containers/Navbar/Dropdown/Converter/content";
import useTranslation from "next-translate/useTranslation";
import { OtherProductItem } from "@/containers/Navbar/Dropdown/Converter/OtherProductItem";
import { ConverterOption } from "@/containers/Navbar/Dropdown/Converter/ConverterOption";
import { useScreenWidth } from "@/hooks/useScreenWidth";
import { Icon } from "@/components/Icon";
import SeoFriendlyAnimateHeight from "@/components/SeoFriendlyAnimateHeight";

declare const Intercom: (...args: unknown[]) => any;

function groupDropdownColumns<T>(arr: T[], columns: number): T[][] {
  const acc: T[][] = Array.from({ length: columns }, () => []);

  for (let i = 0; i < arr.length; i++) {
    acc[i % columns].push(arr[i]);
  }

  return acc;
}

export const ConverterDropdown: React.FC<IDropdownChildren> = ({
  onClose,
  visible,
}) => {
  const { css } = useFela();
  const { colors, bp } = useTheme();
  const { t } = useTranslation();
  const [dropdownColumnAmount, setDropdownColumnAmount] = useState(3);
  const screenWidth = useScreenWidth();
  const [convertersShown, setConvertersShown] = useState(screenWidth > 1000);
  const [otherProductsShown, setOtherProductsShown] = useState(
    screenWidth > 1000,
  );

  const isDesktop = screenWidth > 1200;

  useEffect(() => {
    if (screenWidth < 1000) setDropdownColumnAmount(1);
    else if (screenWidth < 1200) setDropdownColumnAmount(2);
    else setDropdownColumnAmount(3);
  }, [screenWidth]);

  const toggleConverters = () => {
    if (!isDesktop) {
      setConvertersShown(!convertersShown);
    }
  };

  const toggleOtherProducts = () => {
    if (!isDesktop) {
      setOtherProductsShown(!otherProductsShown);
    }
  };

  const dropdownColumns = groupDropdownColumns(
    dropdownElements,
    dropdownColumnAmount,
  );

  return (
    <Card
      customStyle={{
        overflowY: "scroll",
        top: 0,
        left: 0,
        right: 0,
        display: "flex",
        padding: "20px 20px 0 20px",
        borderRadius: 0,
        [bp.ONLY_MOBILE]: {
          boxShadow: "none",
          overflowY: "hidden",
        },
        [bp.FROM_TABLET]: {
          borderRadius: 8,
          overflowY: "hidden",
          position: "relative",
          height: "auto",
          padding: 30,
        },
      }}
    >
      <div
        className={css({
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          [bp.FROM_TABLET]: {
            flexDirection: "row",
          },
          [bp.ONLY_MOBILE]: {
            gap: 16,
          },
        })}
      >
        <div
          className={css({
            display: "flex",
            flexDirection: "column",
          })}
          role="button"
        >
          <div
            role="button"
            className={css({
              display: "flex",
              gap: 12,
              alignItems: "center",
              marginBottom: 12,
              backgroundColor: "none",
            })}
            onClick={toggleConverters}
          >
            <div
              className={css({
                [bp.FROM_TABLET]: {
                  display: "none",
                },
                nested: {
                  "> svg": {
                    transform: convertersShown
                      ? "rotate(0deg)"
                      : "rotate(-90deg)",
                    transition: "transform 0.4s ease",
                  },
                },
              })}
            >
              <Icon iconType="CHEVRON" />
            </div>
            <Heading
              as="h4"
              customStyle={{
                fontWeight: 600,
                fontSize: 14,
                color: "#7F56D9",
              }}
            >
              Design File Converter
            </Heading>
          </div>
          <SeoFriendlyAnimateHeight
            height={convertersShown ? "auto" : 0}
            duration={400}
          >
            <div
              className={css({
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: 32,
                [bp.FROM_TABLET]: {
                  flexDirection: "row",
                },
              })}
            >
              {dropdownColumns.map((group, index) => (
                <div key={index}>
                  {group.map(element => (
                    <ConverterOption
                      onClose={onClose}
                      visible={visible}
                      item={element}
                      key={element.sourceFileType}
                    />
                  ))}
                </div>
              ))}
            </div>
          </SeoFriendlyAnimateHeight>
        </div>
        <div
          className={css({
            padding: 20,
            margin: "0 -20px",
            background: colors.LIGHT_GRAY2,
            borderRadius: 8,
            minWidth: 380,
            [bp.FROM_TABLET]: {
              maxWidth: 380,
              padding: 32,
            },
          })}
        >
          <div
            role="button"
            className={css({
              display: "flex",
              gap: 12,
              alignItems: "center",
              marginBottom: otherProductsShown ? 12 : 0,
              transition: "margin-bottom 0.4s ease",
            })}
            onClick={toggleOtherProducts}
          >
            <div
              className={css({
                [bp.FROM_TABLET]: {
                  display: "none",
                },
                nested: {
                  "> svg": {
                    transform: otherProductsShown
                      ? "rotate(0deg)"
                      : "rotate(-90deg)",
                    transition: "transform 0.4s ease",
                  },
                },
              })}
            >
              <Icon iconType="CHEVRON" />
            </div>
            <Heading
              as="h4"
              customStyle={{
                fontWeight: 600,
                fontSize: 14,
                color: "#7F56D9",
              }}
            >
              Other products
            </Heading>
          </div>
          <SeoFriendlyAnimateHeight
            height={otherProductsShown ? "auto" : 0}
            duration={400}
          >
            <div
              className={css({
                display: "flex",
                flexDirection: "column",
                gap: 8,
                [bp.FROM_TABLET]: {
                  gap: 16,
                },
              })}
            >
              <OtherProductItem
                onClose={onClose}
                title={t(
                  "navigation:converterDropdown.otherProducts.figmaBackup",
                )}
                titleLink="/figma-design-backup"
                text={t(
                  "navigation:converterDropdown.otherProducts.figmaBackupDescription",
                )}
                icon="FIGMA-BACKUP"
              />

              <OtherProductItem
                onClose={onClose}
                title={t(
                  "navigation:converterDropdown.otherProducts.miroBackup",
                )}
                titleLink="/miro-backup"
                text={t(
                  "navigation:converterDropdown.otherProducts.miroBackupDescription",
                )}
                icon="MIRO-BACKUP"
              />

              <OtherProductItem
                onClose={onClose}
                title={t(
                  "navigation:converterDropdown.otherProducts.magiculMonocle",
                )}
                titleLink="/figma-file-sharer"
                text={t(
                  "navigation:converterDropdown.otherProducts.magiculMonocleDescription",
                )}
                icon="MAGICUL-COMMENTS"
              />

              <OtherProductItem
                onClose={onClose}
                title={t("navigation:converterDropdown.otherProducts.magiculX")}
                titleLink="/inspector"
                text={t(
                  "navigation:converterDropdown.otherProducts.magiculXDescription",
                )}
                icon="MAGICUL-X"
              />
              <OtherProductItem
                onClose={onClose}
                title={t(
                  "navigation:converterDropdown.otherProducts.airtableBackup",
                )}
                titleLink="/airtable-backup"
                text={t(
                  "navigation:converterDropdown.otherProducts.airtableBackupDescription",
                )}
                icon="AIRTABLE-BACKUP"
              />
            </div>
          </SeoFriendlyAnimateHeight>
        </div>
      </div>
      <div
        className={css({
          display: "none",
          [bp.FROM_TABLET]: {
            background: colors.LIGHT_GRAY2,
            borderRadius: "0 0 8px 8px",
            padding: "28px 0",
            margin: "28px -24px -24px -24px",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 8,
          },
        })}
      >
        Not seeing what you're looking for?
        <span
          role="button"
          className={css({
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 8,
            color: colors.PURLPE,
            cursor: "pointer",
          })}
          onClick={() => {
            if (typeof Intercom !== "undefined") {
              Intercom("showNewMessage");
            }
          }}
        >
          <Icon iconType="CHAT-ICON" />
          Chat to support
        </span>
      </div>
    </Card>
  );
};
