import React from "react";

import { IAuth } from "./types";

export const initialAuthData: IAuth = {
  loading: true,
  loggedIn: false,
  user: null,

  /* eslint-disable @typescript-eslint/no-empty-function */
  setAuthState: () => {},
  login: () => Promise.resolve(),
  logout: () => {},
  refreshAccount: () => {},
};

export const AuthContext = React.createContext<IAuth>(initialAuthData);
