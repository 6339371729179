import { IConversionType, IConversionTarget, IExtension } from "../../types";

export const extensionMap: Record<string, IExtension> = {
  sketch: ".sketch",
  xd: ".xd",
  figma: ".fig",
  psd: ".psd",
  psb: ".psb",
  ai: ".ai",
  pdf: ".pdf",
  googleslides: ".googleslides",
  eps: ".eps",
  canva: ".canva",
  powerpoint: ".pptx",
  indd: ".indd",
  miro: ".miro",
  express: ".express",
  aftereffects: ".aep",
  jpg: ".jpg",
  freehand: ".freehand",
  keynote: ".key",
};

export const getConversionDetails = (conversionType: IConversionType) => {
  const conversionDetails = conversionType.split("_");

  const conversionSource = conversionDetails
    .shift()
    ?.toLowerCase() as IConversionTarget;

  const conversionTarget = conversionDetails
    .pop()
    ?.toLowerCase() as IConversionTarget;

  return { conversionSource, conversionTarget };
};

export const mergeConversionType = (
  conversionSource: IConversionTarget,
  conversionTarget: IConversionTarget,
): IConversionType => {
  return `${conversionSource.toUpperCase()}_TO_${conversionTarget.toUpperCase()}` as IConversionType;
};

export const getTargetExtension = (
  conversionType: IConversionType,
): IExtension => {
  const { conversionTarget } = getConversionDetails(conversionType);
  const extension = extensionMap[conversionTarget];
  return extension;
};

export const getFileName = (fileNameWithExt?: string): string => {
  const splitFileName = fileNameWithExt?.split(".");
  splitFileName?.pop();
  return splitFileName?.join(".") || "";
};

export const getConversionTypeByExt = (extension: IExtension) =>
  Object.keys(extensionMap).find(key => extensionMap[key] === extension);
