const merge = require("deepmerge");

const converterPageNamespaces = [
  "section/magicians",
  "section/selectFormat",
  "section/trustedBy",
  "section/cta",
  "section/pricing",
  "converter",
  "welcomeAboardModal",
  "successfulPaymentModal",
];

const mergeObjectsInArrByField = (fallback, namespaces) => {
  return fallback.reduce((acc, curr) => {
    const element = namespaces.find(item => item.iconType === curr.iconType);
    if (element) {
      acc.push(element);
    } else {
      acc.push(curr);
    }

    return acc;
  }, []);
};

const mergeOptions = {
  customMerge: key => {
    if (key === "apiTeaserElements") {
      return mergeObjectsInArrByField;
    }
  },
};

module.exports = {
  /**
   * Config for NextJS
   */
  defaultLocale: "en",
  locales: ["en", "de", "fr", "zh", "es"],
  localeDetection: false,

  /**
   * Namespaces resolver
   * We are force loading `en` locale in addition to currently active one
   * to be able to show half-translated pages
   */
  loadLocaleFrom: async (lang, ns) => {
    let namespace;
    let fallbackNamespace;

    await import(`./src/locales/${lang}/${ns}.json`)
      .catch(() => {
        // couldn't read file, probably translation doesn't exist
        return { default: {} };
      })
      .then(m => {
        namespace = m.default;
      });

    await import(`./src/locales/en/${ns}.json`)
      .catch(() => {
        return { default: {} };
      })
      .then(m => {
        fallbackNamespace = m.default;
      });

    return merge(fallbackNamespace, namespace, mergeOptions);
  },

  /**
   * Defining required namespaces for pages
   */
  pages: {
    "*": ["common", "navigation", "fileTypes", "footer", "inquiryForm"],

    // INFO PAGES
    "/account": ["page/account"],
    "/enterprise": [
      "page/enterprise",
      "section/colorfulCta",
      "section/dashboardScreen",
      "section/testimonials",
      "section/convertAllFormats",
      "section/trustedBy",
    ],
    "/legal": ["page/legal"],
    "/pricing": [
      "page/pricing",
      "section/cta",
      "welcomeAboardModal",
      "successfulPaymentModal",
      "section/faq",
      "section/testimonials",
      "section/customersLogos",
      "section/pricing",
      "section/pricingFigmaBackup",
    ],
    "/privacy": ["page/privacy"],
    "/terms-of-service": ["page/terms"],
    "/settings": ["page/settings"],
    "/refund-policy": ["page/refundPolicy"],

    "/": [
      "landingPage",
      ...converterPageNamespaces,
      "section/trustedBy",
      "section/testimonials",
      "section/designEngine",
      "section/requestDemo",
      "section/faq",
    ],

    /**
     * Converter pages
     */
    // XD to *
    "/converter/xd-to-sketch": [
      "page/XDToSketch",
      ...converterPageNamespaces,
      "section/faq",
      "section/convertEverything",
      "section/testimonials",
      "section/blog",
    ],
    "/converter/xd-to-figma": [
      "page/XDToFigma",
      ...converterPageNamespaces,
      "section/convertEverything",
      "section/customersLogos",
      "section/conversion",
      "section/faq",
      "section/testimonials",
      "section/blog",
    ],
    "/converter/xd-to-psd": [
      "page/XDToPSD",
      ...converterPageNamespaces,
      "section/conversion",
    ],
    "/converter/xd-to-ai": [
      "page/XDToAI",
      ...converterPageNamespaces,
      "section/conversion",
    ],
    "/converter/xd-to-pdf": [
      "page/XDToPDF",
      ...converterPageNamespaces,
      "section/conversion",
    ],
    "/converter/xd-to-jpg": [
      "page/XDToJPG",
      ...converterPageNamespaces,
      "section/conversion",
    ],
    "/converter/xd-to-powerpoint": [
      "page/XDToPowerpoint",
      ...converterPageNamespaces,
      "section/conversion",
    ],
    "/converter/xd-to-canva": [
      "page/XDToCanva",
      ...converterPageNamespaces,
      "section/conversion",
    ],

    // Figma to *
    "/converter/figma-to-sketch": [
      "page/FigmaToSketch",
      ...converterPageNamespaces,
      "section/convertEverything",
      "section/conversion",
    ],
    "/converter/figma-to-xd": [
      "page/FigmaToXD",
      ...converterPageNamespaces,
      "section/convertEverything",
      "section/conversion",
    ],
    "/converter/figma-to-psd": [
      "page/FigmaToPSD",
      ...converterPageNamespaces,
      "section/conversion",
    ],
    "/converter/figma-to-ai": [
      "page/FigmaToAI",
      ...converterPageNamespaces,
      "section/customersLogos",
      "section/conversion",
    ],
    "/converter/figma-to-google-slides": [
      "page/FigmaToGoogleSlides",
      ...converterPageNamespaces,
      "section/conversion",
    ],
    "/converter/figma-to-canva": [
      "page/FigmaToCanva",
      ...converterPageNamespaces,
    ],
    "/converter/figma-to-powerpoint": [
      "page/FigmaToPowerpoint",
      ...converterPageNamespaces,
      "section/conversion",
    ],
    "/converter/figma-to-webflow": [
      "page/FigmaToWebflow",
      ...converterPageNamespaces,
      "section/convertEverything",
      "section/conversion",
      "section/testimonials",
      "section/cta",
    ],
    "/converter/figma-to-bubble": [
      "page/FigmaToBubble",
      ...converterPageNamespaces,
      "section/convertEverything",
      "section/conversion",
      "section/testimonials",
      "section/cta",
    ],
    "/converter/figma-to-axure": [
      "page/FigmaToAxure",
      ...converterPageNamespaces,
      "section/conversion",
    ],
    "/converter/figma-to-after-effects": [
      "page/FigmaToAfterEffects",
      ...converterPageNamespaces,
      "section/customersLogos",
      "section/conversion",
    ],
    "/converter/figma-to-pdf": [
      "page/FigmaToPDF",
      ...converterPageNamespaces,
      "section/customersLogos",
      "section/conversion",
    ],
    "/converter/figma-to-indesign": [
      "page/FigmaToIndesign",
      ...converterPageNamespaces,
      "section/conversion",
      "section/customersLogos",
      "section/features",
    ],

    // Sketch to *
    "/converter/sketch-to-ai": [
      "page/SketchToAI",
      ...converterPageNamespaces,
      "section/conversion",
    ],
    "/converter/sketch-to-figma": [
      "page/SketchToFigma",
      ...converterPageNamespaces,
      "section/convertEverything",
      "section/conversion",
    ],
    "/converter/sketch-to-psd": [
      "page/SketchToPSD",
      ...converterPageNamespaces,
      "section/conversion",
    ],
    "/converter/sketch-to-xd": [
      "page/SketchToXD",
      ...converterPageNamespaces,
      "section/convertEverything",
      "section/conversion",
    ],

    // AI to *
    "/converter/ai-to-figma": [
      "page/AIToFigma",
      ...converterPageNamespaces,
      "section/customersLogos",
      "section/conversion",
    ],
    "/converter/ai-to-sketch": [
      "page/AIToSketch",
      ...converterPageNamespaces,
      "section/customersLogos",
      "section/conversion",
    ],

    // PDF to *
    "/converter/pdf-to-figma": [
      "page/PDFToFigma",
      ...converterPageNamespaces,
      "section/customersLogos",
      "section/conversion",
    ],
    "/converter/pdf-to-xd": [
      "page/PDFToXD",
      ...converterPageNamespaces,
      "section/customersLogos",
      "section/conversion",
    ],
    "/converter/pdf-to-psd": ["page/PDFToPSD", ...converterPageNamespaces],
    "/converter/pdf-to-sketch": [
      "page/PDFToSketch",
      ...converterPageNamespaces,
      "section/customersLogos",
      "section/conversion",
    ],
    "/converter/pdf-to-ai": [
      "page/PDFToAI",
      ...converterPageNamespaces,
      "section/convertEverything",
      "section/testimonials",
    ],
    "/converter/pdf-to-indesign": [
      "page/PDFToIndesign",
      ...converterPageNamespaces,
      "section/customersLogos",
      "section/conversion",
    ],

    // PSD to *
    "/converter/psd-to-figma": [
      "page/PSDToFigma",
      ...converterPageNamespaces,
      "section/conversion",
    ],
    "/converter/psd-to-sketch": [
      "page/PSDToSketch",
      ...converterPageNamespaces,
      "section/conversion",
    ],
    "/converter/psd-to-canva": [
      "page/PSDToCanva",
      ...converterPageNamespaces,
      "section/customersLogos",
      "section/conversion",
    ],

    // Google Slides to *
    "/converter/google-slides-to-figma": [
      "page/GoogleSlidesToFigma",
      ...converterPageNamespaces,
      "section/conversion",
    ],

    "/converter/google-slides-to-canva": [
      "page/GoogleSlidesToCanva",
      ...converterPageNamespaces,
      "section/conversion",
    ],

    // Keynote to *
    "/converter/keynote-to-figma": [
      "page/KeynoteToFigma",
      ...converterPageNamespaces,
      "section/conversion",
    ],

    "/converter/keynote-to-xd": [
      "page/KeynoteToXd",
      ...converterPageNamespaces,
      "section/conversion",
    ],

    "/converter/keynote-to-sketch": [
      "page/KeynoteToSketch",
      ...converterPageNamespaces,
      "section/conversion",
    ],

    // EPS to *
    "/converter/eps-to-figma": [
      "page/EPSToFigma",
      ...converterPageNamespaces,
      "section/customersLogos",
      "section/conversion",
    ],

    // Canva to *
    "/converter/canva-to-figma": [
      "page/CanvaToFigma",
      ...converterPageNamespaces,
      "section/conversion",
    ],
    "/converter/canva-to-powerpoint": [
      "page/CanvaToPowerpoint",
      ...converterPageNamespaces,
      "section/conversion",
    ],
    "/converter/canva-to-googleslides": [
      "page/CanvaToGoogleslides",
      ...converterPageNamespaces,
      "section/conversion",
    ],
    "/converter/canva-to-psd": [
      "page/CanvaToPSD",
      ...converterPageNamespaces,
      "section/conversion",
    ],
    "/converter/canva-to-xd": [
      "page/CanvaToXd",
      ...converterPageNamespaces,
      "section/conversion",
    ],
    "/converter/canva-to-ai": [
      "page/CanvaToAI",
      ...converterPageNamespaces,
      "section/conversion",
    ],
    "/converter/canva-to-indesign": [
      "page/CanvaToIndesign",
      ...converterPageNamespaces,
      "section/conversion",
    ],
    "/converter/canva-to-html": [
      "page/CanvaToHTML",
      ...converterPageNamespaces,
      "section/conversion",
    ],
    "/converter/canva-to-wordpress": [
      "page/CanvaToWordpress",
      ...converterPageNamespaces,
      "section/conversion",
    ],

    // Indesign to *
    "/converter/indesign-to-figma": [
      "page/IndesignToFigma",
      ...converterPageNamespaces,
      "section/conversion",
      "section/customersLogos",
      "section/features",
    ],
    "/converter/indesign-to-xd": [
      "page/IndesignToXd",
      ...converterPageNamespaces,
      "section/conversion",
      "section/customersLogos",
      "section/features",
    ],
    "/converter/indesign-to-sketch": [
      "page/IndesignToSketch",
      ...converterPageNamespaces,
      "section/conversion",
      "section/customersLogos",
      "section/features",
    ],

    // Miro to *
    "/converter/miro-to-figjam": [
      "page/MiroToFigjam",
      ...converterPageNamespaces,
      "section/convertEverything",
      "section/testimonials",
    ],
    "/converter/miro-to-lucidchart": [
      "page/MiroToLucidChart",
      ...converterPageNamespaces,
      "section/convertEverything",
      "section/testimonials",
    ],
    "/converter/miro-to-mural": [
      "page/MiroToMural",
      ...converterPageNamespaces,
      "section/convertEverything",
      "section/testimonials",
    ],
    // Mural to *
    "/converter/mural-to-miro": [
      "page/MuralToMiro",
      ...converterPageNamespaces,
      "section/convertEverything",
      "section/testimonials",
    ],
    // LucidChart to *
    "/converter/lucidchart-to-miro": [
      "page/LucidChartToMiro",
      ...converterPageNamespaces,
      "section/convertEverything",
      "section/testimonials",
    ],
    "/converter/lucidchart-to-figjam": [
      "page/LucidChartToFigjam",
      ...converterPageNamespaces,
      "section/convertEverything",
      "section/testimonials",
    ],
    // Invision Freehand to *
    "/converter/freehand-to-figjam": [
      "page/FreehandToFigjam",
      ...converterPageNamespaces,
      "section/convertEverything",
      "section/testimonials",
    ],
    // Figjam to *
    "/converter/figjam-to-miro": [
      "page/FigjamToMiro",
      ...converterPageNamespaces,
      "section/convertEverything",
      "section/testimonials",
      "section/conversion",
    ],
    "/converter/figjam-to-lucidchart": [
      "page/FigjamToLucidChart",
      ...converterPageNamespaces,
      "section/convertEverything",
      "section/testimonials",
    ],

    // PowerPoint to *
    "/converter/powerpoint-to-figma": [
      "page/PowerPointToFigma",
      ...converterPageNamespaces,
      "section/convertEverything",
      "section/testimonials",
    ],

    "/converter/powerpoint-to-canva": [
      "page/PowerPointToCanva",
      ...converterPageNamespaces,
      "section/convertEverything",
      "section/testimonials",
    ],

    "/converter/powerpoint-to-sketch": [
      "page/PowerPointToSketch",
      ...converterPageNamespaces,
      "section/convertEverything",
      "section/testimonials",
    ],

    "/converter/powerpoint-to-xd": [
      "page/PowerPointToXd",
      ...converterPageNamespaces,
      "section/convertEverything",
      "section/testimonials",
    ],

    "/converter/powerpoint-to-psd": [
      "page/PowerPointToPsd",
      ...converterPageNamespaces,
      "section/convertEverything",
      "section/testimonials",
    ],

    "/converter/powerpoint-to-ai": [
      "page/PowerPointToAi",
      ...converterPageNamespaces,
      "section/convertEverything",
      "section/testimonials",
    ],

    // Website to *
    "/converter/website-to-figma": [
      "page/WebsiteToFigma",
      ...converterPageNamespaces,
      "section/conversion",
      "section/testimonials",
    ],

    // Adobe express to *
    "/converter/express-to-figma": [
      "page/ExpressToFigma",
      ...converterPageNamespaces,
      "section/conversion",
      "section/customersLogos",
      "section/features",
    ],

    "/converter/express-to-canva": [
      "page/ExpressToCanva",
      ...converterPageNamespaces,
      "section/conversion",
      "section/customersLogos",
      "section/features",
    ],

    // Microsoft Visio to *
    "/converter/visio-to-figjam": [
      "page/VisioToFigjam",
      ...converterPageNamespaces,
      "section/convertEverything",
      "section/testimonials",
    ],

    // FILES PAGES
    "/files": ["page/files"],
    "/file/[fileName]": [
      "page/filename",
      "converter",
      "section/magicians",
      "welcomeAboardModal",
      "successfulPaymentModal",
      "payment",
      "section/pricing",
      "downloadHelpArticleModal",
    ],

    // OTHER PAGES
    "/open-xd-file-old-corrupted": [
      "page/open-xd-file-old-corrupted",
      "page/filename",
      "converter",
      "section/testimonials",
      "section/brokenXD",
      "section/customersLogos",
      "section/description",
      "section/pricing",
      "section/cta",
    ],
    "/figma-design-backup": [
      "page/figma-design-backup",
      // TODO: merge trustedBy and customersLogos translations
      "section/trustedBy",
      "section/cta",
      "section/colorfulCta",
      "section/dashboardScreen",
      "section/pricing",
      "section/pricingFigmaBackup",
      "successfulPaymentModal",
      "section/figmaBackupFaq",
      "freeTrialModal",
      "welcomeAboardModal",
    ],
    "/miro-backup": ["page/miro-backup", "section/trustedBy", "section/cta"],
    "/airtable-backup": [
      "page/airtable-backup",
      "section/trustedBy",
      "section/cta",
      "section/airtableBackupFaq",
    ],
    "/converter-features": ["page/converterFeatures"],
    "/solutions/for-agencies": [
      "page/solutionsForAgencies",
      "section/requestDemo",
      "section/testimonials",
      "section/trustedBy",
    ],
    "/solutions/for-enterprise": [
      "page/solutionsForEnterprise",
      "section/requestDemo",
      "section/enterpriseSolutionsFaq",
    ],

    // TUTORIALS PAGES
    "/tutorials/import-figma-in-canva-using-plugin": [
      "page/import-figma-in-canva",
    ],
    "/tutorials/open-xd-file-flinto": ["page/open-xd-file-flinto"],
    "/tutorials/open-xd-file-principle": ["page/open-xd-file-principle"],
    "/tutorials/open-xd-in-builderx": ["page/open-xd-in-builderx"],
    "/tutorials/open-xd-in-uxpin": ["page/open-xd-in-uxpin"],
    "/tutorials/figma-components-to-xd": [
      "page/figma-components-to-xd",
      "section/testimonials",
      "section/cta",
      "section/newsletterSignup",
    ],
    "/tutorials/xd-components-to-figma": [
      "page/xd-components-to-figma",
      "section/cta",
      "section/newsletterSignup",
    ],
    "/inspector": ["page/Inspector", "section/contact"],
    "/extract-images-from-xd": [
      "page/ExtractImagesFromXD",
      "section/magicians",
      "converter",
    ],
  },

  /**
   * Misc
   */
  logBuild: false,
};
