import React from "react";
import { useFela } from "react-fela";
import { useTheme, IWithCustomStyle } from "@/hooks/useTheme";

export const Card: React.FC<IWithCustomStyle> = ({
  customStyle = {},
  ...rest
}) => {
  const { css } = useFela();
  const { colors, bp } = useTheme();

  return (
    <div
      className={css(
        {
          flex: 1,
          padding: 20,
          borderRadius: 8,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          backgroundColor: colors.WHITE,
          boxShadow: `20px 20px 80px 0 ${colors.CETACEAN_BLUE}`,
          [bp.FROM_TABLET]: {
            padding: 30,
          },
        },
        customStyle,
      )}
      {...rest}
    />
  );
};
