import React from "react";
import { useFela } from "react-fela";

import { useTheme } from "@/hooks/useTheme";
import { IWithCustomStyle } from "@/hooks/useTheme/types";

interface IBadge {
  size?: number;
}

type IProps = IBadge & IWithCustomStyle & JSX.IntrinsicElements["div"];

export const Badge: React.FC<IProps> = ({
  size = 48,
  customStyle = {},
  ...rest
}) => {
  const { css } = useFela();

  return (
    <div
      className={css(
        {
          width: size,
          height: size,
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        customStyle,
      )}
      {...rest}
    />
  );
};

export const TextBadge: React.FC<
  IWithCustomStyle & JSX.IntrinsicElements["div"]
> = ({ customStyle = {}, ...rest }) => {
  const { css } = useFela();
  const { colors } = useTheme();

  return (
    <div
      className={css(
        {
          height: 25,
          fontSize: 13,
          fontWeight: 800,
          display: "flex",
          padding: "6px 12px",
          borderRadius: 14.5,
          alignItems: "center",
          justifyContent: "center",
          textTransform: "uppercase",
          background: colors.WHITE,
          boxShadow: "0 2px 11px 0 rgba(0, 0, 0, 0.13)",
        },
        customStyle,
      )}
      {...rest}
    />
  );
};
