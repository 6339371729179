import React from "react";
import { useFela } from "react-fela";

import { useTheme } from "@/hooks/useTheme";

export const ErrorMessage: React.FC = props => {
  const { css } = useFela();
  const { colors } = useTheme();

  return (
    <div
      className={css({
        marginTop: -25,
        marginBottom: 15,
        fontSize: 12,
        letterSpacing: 0.5,
        color: colors.RED,
      })}
    >
      {props.children}
    </div>
  );
};
