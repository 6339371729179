import React, { FC, useState } from "react";
import AnimateHeight from "react-animate-height";
import { Heading } from "@/components/Heading";
import { Icon } from "@/components/Icon";
import { useFela } from "react-fela";

type IDropdownToggler = {
  title: string;
  children?: JSX.Element;
};

export const DropdownToggler: FC<IDropdownToggler> = ({ children, title }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { css } = useFela();

  return (
    <div>
      <div
        role="button"
        className={css({
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 0",
        })}
        onClick={() => setIsOpen(open => !open)}
      >
        <Heading customStyle={{ marginBottom: 0, fontSize: 16 }} as="h3">
          {title}
        </Heading>
        {children && (
          <div
            className={css({
              transform: isOpen ? "rotate(0deg)" : "rotate(180deg)",
              transition: "transform 0.3s ease",
              display: "grid",
              placeItems: "center",
            })}
          >
            <Icon iconType="CHEVRON" />
          </div>
        )}
      </div>
      {children && (
        <AnimateHeight height={isOpen ? "auto" : 0} duration={400}>
          {React.cloneElement(children, { visible: isOpen })}
        </AnimateHeight>
      )}
    </div>
  );
};
