import { IFooterContent } from "./types";

export const content: IFooterContent = {
  socials: [
    {
      href: "https://facebook.com/magicul.io",
      iconType: "FACEBOOK",
      title: "Facebook icon",
    },
    {
      href: "https://medium.com/xd2sketch",
      iconType: "MEDIUM",
      title: "Medium icon",
    },
    {
      href: "https://www.youtube.com/channel/UCWcKb64il19wHifcxsSc_2g",
      iconType: "YOUTUBE",
      title: "Youtube icon",
    },
    {
      href: "https://www.instagram.com/magicul.io/",
      iconType: "INSTAGRAM-ICON",
      title: "Instagram icon",
    },
  ],

  menu: [
    { title: "converterFeatures", href: "/converter-features" },
    { title: "corruptedAndBrokenFile", href: "/open-xd-file-old-corrupted" },
    { title: "extractImagesFromXD", href: "/extract-images-from-xd" },
    { title: "figmaCloudBackup", href: "/figma-design-backup" },
    { title: "miroBackup", href: "/miro-backup" },
    { title: "blog", href: "/blog", target: "_blank" },
    { title: "support", href: "mailto:support@magicul.io" },
    { title: "pricing", href: "/pricing" },
    { title: "termsOfService", href: "/terms-of-service" },
    { title: "privacy", href: "/privacy" },
    { title: "legal", href: "/legal" },
    { title: "refundPolicy", href: "/refund-policy" },
  ],

  guides: [
    {
      title: "importFigmaInCanva",
      href: "/tutorials/import-figma-in-canva-using-plugin",
    },
    { title: "xdInPrinciple", href: "/tutorials/open-xd-file-principle" },
    { title: "importXdInUXPin", href: "/tutorials/open-xd-in-uxpin" },
    { title: "openXdInFlinto", href: "/tutorials/open-xd-file-flinto" },
    { title: "openXdInBuilderX", href: "/tutorials/open-xd-in-builderx" },
    { title: "xdComponentsToFigma", href: "/tutorials/xd-components-to-figma" },
    { title: "figmaComponentsToXd", href: "/tutorials/figma-components-to-xd" },
  ],
};
