import React from "react";
import { useFela } from "react-fela";

import { Background } from "@/components/Background";
import { IWithCustomStyle } from "@/hooks/useTheme/types";
import { useScrollLock } from "@/hooks/useScrollLock";
import { useOnClickOutside } from "@/hooks/useOnClickOutside";
import { useTheme } from "@/hooks/useTheme";
import { Icon } from "../Icon";

interface IModal {
  id: string;
  dataTestId?: string;
  onClose: () => void;
}

type IProps = IModal & IWithCustomStyle;

export const Modal: React.FC<IProps> = ({
  id,
  dataTestId,
  children,
  onClose,
  customStyle = {},
}) => {
  const { css } = useFela();
  const { colors } = useTheme();
  const ref = React.useRef<HTMLDivElement>(null);

  useScrollLock(true);

  useOnClickOutside(ref, onClose);

  return (
    <Background variant="dark">
      <div
        id={id}
        ref={ref}
        data-testid={dataTestId}
        className={css(
          {
            padding: 20,
            maxWidth: 1280,
            position: "relative",
            maxHeight: "80vh",
            margin: "0 auto",
            borderRadius: 8,
            backgroundColor: colors.WHITE,
            overflowY: "auto",
          },
          customStyle,
        )}
      >
        <button
          type="button"
          onClick={onClose}
          className={css({
            position: "absolute",
            top: 20,
            right: 20,
            margin: 0,
            padding: 0,
            cursor: "pointer",
            background: "none",
            border: "none",
            nested: {
              ":hover": {
                nested: {
                  "> svg": {
                    opacity: 0.6,
                  },
                },
              },
              "> svg": {
                width: 18,
                height: 18,
                transition: "opacity .15s",
              },
            },
          })}
        >
          <Icon iconType="CLOSE" />
        </button>

        <div
          className={css({
            marginTop: 40,
          })}
        >
          {children}
        </div>
      </div>
    </Background>
  );
};
