import React from "react";

export const DynamicContent: React.FC = ({ children }) => {
  const [isClient, setIsClient] = React.useState(false);

  React.useEffect(() => {
    setIsClient(true);
  }, []);

  return isClient ? <>{children}</> : null;
};
