import React from "react";

import { ITheme, IWithCustomStyle, IColor } from "./types";
import { theme } from "./theme";

const ThemeContext = React.createContext<ITheme | null>(null);

export const useTheme = () => {
  const context = React.useContext(ThemeContext);

  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};

export const ThemeProvider: React.FC = props => {
  return <ThemeContext.Provider value={theme} {...props} />;
};

export type { ITheme, IWithCustomStyle, IColor };
