
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { IRenderer } from "fela";
import App from "next/app";
import Head from "next/head";
import React from "react";
import nprogress from "nprogress";
import router from "next/router";
import getConfig from "next/config";
import * as Sentry from "@sentry/node";
import { CookiesProvider } from "react-cookie";
import Axios from "axios";

import { ThemeProvider } from "@/hooks/useTheme";
import { FelaProvider } from "@/hooks/useTheme/fela";
import { AuthProvider } from "@/hooks/useAuth";
import { AppContextProvider } from "@/hooks/useAppContext";
import { InquiryFormContextProvider } from "@/hooks/useInquiryForm";
import { CookiesBanner } from "@/components/CookiesBanner";
import { Header } from "@/containers/Header";
import { Footer } from "@/containers/Footer";
import { ProductHuntBanner } from "@/components/ProductHuntBanner";
import Trans from "next-translate/Trans";
import { Link } from "@/components/Link";
import { ChargebeeScript } from "@/scripts/Chargebee";

nprogress.configure({ showSpinner: false });
router.events.on("routeChangeStart", () => nprogress.start());
router.events.on("routeChangeComplete", () => nprogress.done());
router.events.on("routeChangeError", () => nprogress.done());

const { publicRuntimeConfig } = getConfig();
const isDev = process.env.NODE_ENV !== "production";

Sentry.init({
  environment: isDev ? "DEV" : "PROD",
  release: publicRuntimeConfig.version,
  dsn: process.env.NEXT_PUBLIC_XD2SKETCH_SENTRY_DSN,
  enabled: !isDev,
});

// include cookies in every request
Axios.defaults.withCredentials = true;

class MyApp extends App<{ renderer?: IRenderer }> {
  async componentDidMount() {
    const smoothscroll = (await import("smoothscroll-polyfill")).default;
    smoothscroll.polyfill();

    const insertTagManager = async () => {
      const TagManager = (await import("react-gtm-module")).default;

      const tagManagerArgs = {
        gtmId: process.env.NEXT_PUBLIC_XD2SKETCH_GTM_ID,
        auth: process.env.NEXT_PUBLIC_XD2SKETCH_GTM_AUTH,
        preview: process.env.NEXT_PUBLIC_XD2SKETCH_GTM_PREVIEW,
      };

      TagManager.initialize(tagManagerArgs);
    };

    window.addEventListener("load", insertTagManager);
  }

  componentDidCatch(error: Error, errorInfo: any) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      scope.setTag("where", "Global: _app.componentDidCatch");
      Sentry.captureException(error);
    });
  }

  render() {
    const { Component, pageProps, renderer } = this.props;

    // NOTE change this to add banner
    const bannerText: string | undefined = undefined;

    return (
      <>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Head>

        <ChargebeeScript />

        <FelaProvider renderer={renderer}>
          <ThemeProvider>
            <AppContextProvider>
              <InquiryFormContextProvider>
                <AuthProvider>
                  <ProductHuntBanner
                    text={
                      <Trans
                        i18nKey="common:featureBannerText"
                        components={[
                          <span
                            key="0"
                            style={{
                              color: "#fff",
                              background: "#FF5A60",
                              padding: "2px 5px",
                              marginRight: 10,
                              borderRadius: 10,
                              fontWeight: "bold",
                            }}
                          />,
                          <Link
                            key="1"
                            href="/figma-design-backup"
                            customStyle={{
                              marginLeft: 10,
                              color: "#FF5A60",
                              borderBottom: "none",
                              whiteSpace: "nowrap",
                            }}
                          />,
                        ]}
                      />
                    }
                  />
                  <Header bannerText={bannerText} />
                  <main>
                    <Component {...pageProps} />
                  </main>
                </AuthProvider>

                <Footer />
              </InquiryFormContextProvider>

              <CookiesProvider>
                <CookiesBanner />
              </CookiesProvider>
            </AppContextProvider>
          </ThemeProvider>
        </FelaProvider>
      </>
    );
  }
}

const __Page_Next_Translate__ = MyApp;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      
// @ts-ignore
    });
// @ts-ignore
  