import React from "react";
import webPreset from "fela-preset-web";
import prefixer from "fela-plugin-prefixer";
import typescript from "fela-plugin-typescript";
import { createRenderer, IRenderer } from "fela";
import { RendererProvider } from "react-fela";

export const getFelaRenderer = () =>
  createRenderer({
    plugins: [...webPreset, typescript(), prefixer()],
  });

const fallbackRenderer = getFelaRenderer();

export const FelaProvider: React.FC<{ renderer?: IRenderer }> = ({
  renderer,
  children,
}) => {
  return (
    <RendererProvider renderer={renderer || fallbackRenderer}>
      {children}
    </RendererProvider>
  );
};
