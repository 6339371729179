import React from "react";
import { useFela } from "react-fela";
import { IWithCustomStyle } from "../../hooks/useTheme/types";
import { useTheme } from "../../hooks/useTheme";

interface IHeading {
  as?: "h1" | "h2" | "h3" | "h4";
}

export const Heading: React.FC<IHeading & IWithCustomStyle> = ({
  as: Element = "h1",
  customStyle = {},
  children,
  ...rest
}) => {
  const { css } = useFela();
  const { colors, bp, fonts } = useTheme();

  return (
    <div
      className={css({
        nested: {
          "> h1": {
            fontFamily: fonts.PRIMARY_FONT,
            fontSize: 34,
            lineHeight: 1.3,
            textAlign: "center",
            margin: 0,
            marginBottom: 24,
            [bp.FROM_DESKTOP]: {
              fontSize: 48,
              lineHeight: 1.38,
            },
            ...customStyle,
          },
          "> h2": {
            margin: 0,
            fontSize: 28,
            letterSpacing: 1,
            marginBottom: 20,
            fontWeight: "bold",
            color: colors.DARK_GREY,
            [bp.FROM_DESKTOP]: {
              fontSize: 36,
              marginBottom: 30,
            },
            ...customStyle,
          },
          "> h3": {
            margin: 0,
            marginBottom: 32,
            fontSize: 22,
            letterSpacing: 0.6,
            color: colors.DARK_GREY,
            [bp.FROM_TABLET]: {
              fontSize: 24,
            },
            ...customStyle,
          },
          "> h4": {
            margin: 0,
            fontSize: 18,
            color: colors.DARK_GREY,
            ...customStyle,
          },
        },
      })}
    >
      <Element className={css(customStyle)} {...rest}>
        {children}
      </Element>
    </div>
  );
};
