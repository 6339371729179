import React from "react";
import { useFela } from "react-fela";

import { IWithCustomStyle } from "../../hooks/useTheme/types";
import { useTheme } from "../../hooks/useTheme";

type IProps = IWithCustomStyle & JSX.IntrinsicElements["input"];

export const Input = React.forwardRef<HTMLInputElement, IProps>(
  ({ customStyle = {}, ...rest }, ref) => {
    const { css } = useFela();
    const { colors } = useTheme();

    return (
      <input
        ref={ref}
        className={css(
          {
            padding: "6px 14px",
            color: colors.DARK_GREY,
            fontSize: 14,
            borderRadius: 4,
            lineHeight: 2,
            border: `1px solid ${colors.STARDUST}`,
            nested: {
              ":focus": {
                outline: 0,
              },
            },
          },
          customStyle,
        )}
        {...rest}
      />
    );
  },
);
