import React, { useState } from "react";
import { useFela } from "react-fela";

import { useTheme } from "@/hooks/useTheme";
import { Icon } from "@/components/Icon";
import { Link } from "@/components/Link";
import { Heading } from "@/components/Heading";
import { IOtherProductItem } from "./types";

export const OtherProductItem: React.FC<IOtherProductItem> = ({
  title,
  titleLink,
  text,
  icon,
  onClose,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const { css } = useFela();
  const { colors } = useTheme();

  return (
    <Link
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      href={titleLink}
      className={css({
        display: "flex",
        textDecoration: "none",
        gap: 8,
        marginLeft: "-12px",
        padding: 12,
        borderRadius: 8,
        background: isHovered ? colors.WHITE : "transparent",
        transition: "background 0.2s ease-in-out",
      })}
      onClick={onClose}
    >
      <div
        className={css({
          minWidth: 80,
          minHeight: 80,
          height: 80,
          width: 80,
          background: isHovered ? "#F5F3FF" : colors.WHITE,
          borderRadius: 8,
          display: "grid",
          placeItems: "center",
          transition: "background 0.2s ease-in-out",
        })}
      >
        <Icon iconType={icon} />
      </div>
      <div
        className={css({
          display: "flex",
          flexDirection: "column",
          gap: 4,
        })}
      >
        <Heading
          as="h4"
          customStyle={{
            fontWeight: 600,
            fontSize: 17,
            color: "#101828",
            marginBottom: 0,
            position: "relative",
          }}
        >
          {title}
          <div
            className={css({
              position: "absolute",
              right: 0,
              top: 0,
              opacity: isHovered ? 1 : 0,
              transform: isHovered ? "translateX(0)" : "translateX(8px)",
              transition: "all 0.7s cubic-bezier(0.1, 0.7, 0.1, 1)",
            })}
          >
            <Icon iconType="ARROW-RIGHT-SMALL" />
          </div>
        </Heading>
        <p
          className={css({
            margin: 0,
            fontSize: 14,
            color: "#475467",
            fontWeight: 400,
          })}
        >
          {text}
        </p>
      </div>
    </Link>
  );
};
