import { IIconType } from "@/icons";

import { IConversionType, IConversionTarget } from "./types";

export const landingPagesMap: Record<IConversionType, string> = {
  XD_TO_SKETCH: "/converter/xd-to-sketch",
  XD_TO_FIGMA: "/converter/xd-to-figma",
  SKETCH_TO_XD: "/converter/sketch-to-xd",
  SKETCH_TO_FIGMA: "/converter/sketch-to-figma",
  FIGMA_TO_SKETCH: "/converter/figma-to-sketch",
  FIGMA_TO_XD: "/converter/figma-to-xd",
  FIGMA_TO_WEBFLOW: "/converter/figma-to-webflow",
  FIGMA_TO_BUBBLE: "/converter/figma-to-bubble",
  SKETCH_TO_PSD: "/converter/sketch-to-psd",
  XD_TO_PSD: "/converter/xd-to-psd",
  FIGMA_TO_PSD: "/converter/figma-to-psd",
  AI_TO_FIGMA: "/converter/ai-to-figma",
  AI_TO_SKETCH: "/converter/ai-to-sketch",
  PDF_TO_FIGMA: "/converter/pdf-to-figma",
  PDF_TO_SKETCH: "/converter/pdf-to-sketch",
  PDF_TO_XD: "/converter/pdf-to-xd",
  PDF_TO_PSD: "/converter/pdf-to-psd",
  FIGMA_TO_AI: "/converter/figma-to-ai",
  PSD_TO_FIGMA: "/converter/psd-to-figma",
  PSD_TO_SKETCH: "/converter/psd-to-sketch",
  GOOGLESLIDES_TO_FIGMA: "/converter/google-slides-to-figma",
  GOOGLESLIDES_TO_CANVA: "/converter/google-slides-to-canva",
  FIGMA_TO_GOOGLESLIDES: "/converter/figma-to-google-slides",
  FIGMA_TO_POWERPOINT: "/converter/figma-to-powerpoint",
  SKETCH_TO_AI: "/converter/sketch-to-ai",
  XD_TO_AI: "/converter/xd-to-ai",
  EPS_TO_FIGMA: "/converter/eps-to-figma",
  CANVA_TO_FIGMA: "/converter/canva-to-figma",
  FIGMA_TO_CANVA: "/converter/figma-to-canva",
  INDD_TO_FIGMA: "/converter/indesign-to-figma",
  INDD_TO_XD: "/converter/indesign-to-xd",
  INDD_TO_SKETCH: "/converter/indesign-to-sketch",
  MIRO_TO_FIGJAM: "/converter/miro-to-figjam",
  MIRO_TO_LUCIDCHART: "/converter/miro-to-lucidchart",
  MIRO_TO_MURAL: "/converter/miro-to-mural",
  FIGJAM_TO_MIRO: "/converter/figjam-to-miro",
  FIGJAM_TO_LUCIDCHART: "/converter/figjam-to-lucidchart",
  POWERPOINT_TO_FIGMA: "/converter/powerpoint-to-figma",
  POWERPOINT_TO_SKETCH: "/converter/powerpoint-to-sketch",
  POWERPOINT_TO_XD: "/converter/powerpoint-to-xd",
  POWERPOINT_TO_AI: "/converter/powerpoint-to-ai",
  POWERPOINT_TO_CANVA: "/converter/powerpoint-to-canva",
  POWERPOINT_TO_PSD: "/converter/powerpoint-to-psd",
  PDF_TO_AI: "/converter/pdf-to-ai",
  PDF_TO_INDD: "/converter/pdf-to-indesign",
  WEBSITE_TO_FIGMA: "/converter/website-to-figma",
  FIGMA_TO_AXURE: "/converter/figma-to-axure",
  EXPRESS_TO_FIGMA: "/converter/express-to-figma",
  EXPRESS_TO_CANVA: "/converter/express-to-canva",
  FIGMA_TO_AFTEREFFECTS: "/converter/figma-to-after-effects",
  FIGMA_TO_PDF: "/converter/figma-to-pdf",
  XD_TO_PDF: "/converter/xd-to-pdf",
  CANVA_TO_POWERPOINT: "/converter/canva-to-powerpoint",
  CANVA_TO_GOOGLESLIDES: "/converter/canva-to-googleslides",
  CANVA_TO_PSD: "/converter/canva-to-psd",
  CANVA_TO_AI: "/converter/canva-to-ai",
  CANVA_TO_INDD: "/converter/canva-to-indesign",
  CANVA_TO_HTML: "/converter/canva-to-html",
  CANVA_TO_WORDPRESS: "/converter/canva-to-wordpress",
  CANVA_TO_XD: "/converter/canva-to-xd",
  XD_TO_JPG: "/converter/xd-to-jpg",
  XD_TO_POWERPOINT: "/converter/xd-to-powerpoint",
  FREEHAND_TO_FIGJAM: "/converter/freehand-to-figjam",
  LUCIDCHART_TO_MIRO: "/converter/lucidchart-to-miro",
  LUCIDCHART_TO_FIGJAM: "/converter/lucidchart-to-figjam",
  XD_TO_CANVA: "/converter/xd-to-canva",
  PSD_TO_CANVA: "/converter/psd-to-canva",
  MURAL_TO_MIRO: "/converter/mural-to-miro",
  KEYNOTE_TO_FIGMA: "/converter/keynote-to-figma",
  KEYNOTE_TO_XD: "/converter/keynote-to-xd",
  KEYNOTE_TO_SKETCH: "/converter/keynote-to-sketch",
  FIGMA_TO_INDD: "/converter/figma-to-indesign",
  VISIO_TO_FIGJAM: "/converter/visio-to-figjam",
};

export const formatToIconMap: Record<IConversionTarget, IIconType> = {
  xd: "ADOBEXD",
  sketch: "SKETCH",
  figma: "FIGMA",
  psd: "PHOTOSHOP",
  ai: "AI",
  pdf: "PDF",
  googleslides: "GOOGLE-SLIDES",
  eps: "EPS_FILE",
  canva: "CANVA",
  indd: "INDESIGN",
  powerpoint: "POWERPOINT",
  webflow: "WEBFLOW",
  miro: "MIRO",
  mural: "MURAL",
  figjam: "FIGJAM",
  bubble: "BUBBLE",
  website: "WEBSITE-ICON",
  axure: "AXURE-ICON",
  express: "EXPRESS-ICON",
  aftereffects: "AFTER-EFFECTS-ICON",
  jpg: "JPG-ICON",
  freehand: "FREEHAND-ICON",
  lucidchart: "LUCIDCHART",
  keynote: "KEYNOTE",
  html: "HTML_ICON",
  wordpress: "WORDPRESS_ICON",
  visio: "VISIO_ICON",
};

export const formatToFileIconMap: Record<IConversionTarget, IIconType> = {
  xd: "ADOBEXD_FILE",
  sketch: "SKETCH_FILE",
  figma: "FIGMA_FILE",
  psd: "PSD_FILE",
  ai: "AI_FILE",
  pdf: "PDF_FILE",
  googleslides: "GOOGLE-SLIDES-FILE-ICON",
  eps: "EPS_FILE",
  canva: "CANVA-FILE-ICON",
  indd: "INDESIGN-FILE-ICON",
  powerpoint: "POWERPOINT-FILE-ICON",
  webflow: "WEBFLOW",
  miro: "MIRO-FILE-ICON",
  mural: "MURAL",
  figjam: "FIGJAM_FILE",
  bubble: "BUBBLE",
  website: "WEBSITE-ICON",
  axure: "AXURE-ICON",
  express: "EXPRESS-ICON",
  aftereffects: "AFTER-EFFECTS-ICON",
  jpg: "JPG-ICON",
  freehand: "FREEHAND-ICON",
  lucidchart: "LUCIDCHART",
  keynote: "KEYNOTE",
  html: "HTML_ICON",
  wordpress: "WORDPRESS_ICON",
  visio: "VISIO_ICON",
};

export const extensionNamesMap: Record<IConversionTarget, any> = {
  xd: "Adobe XD",
  sketch: "Sketch",
  figma: "Figma",
  psd: "Photoshop",
  ai: "Illustrator",
  pdf: "PDF",
  googleslides: "Google Slides",
  eps: "EPS",
  canva: "Canva",
  indd: "Indesign",
  powerpoint: "Powerpoint",
  webflow: "Webflow",
  miro: "Miro",
  mural: "Mural",
  figjam: "Figjam",
  bubble: "Bubble",
  // TODO: change this
  website: "Website",
  axure: "Axure",
  express: "Adobe Express",
  aftereffects: "After Effects",
  jpg: "JPG",
  freehand: "Invision Freehand",
  lucidchart: "LucidChart",
  keynote: "Keynote",
  html: "HTML",
  wordpress: "Wordpress",
  visio: "Visio",
};

export const betaConversionFormats: IConversionType[] = [
  "CANVA_TO_GOOGLESLIDES",
  "CANVA_TO_INDD",
  "CANVA_TO_HTML",
  "EXPRESS_TO_FIGMA",
  "EXPRESS_TO_CANVA",
  "FIGJAM_TO_LUCIDCHART",
  "FIGMA_TO_AFTEREFFECTS",
  "FIGMA_TO_AXURE",
  "FIGMA_TO_BUBBLE",
  "FIGMA_TO_CANVA",
  "FIGMA_TO_GOOGLESLIDES",
  "FIGMA_TO_PDF",
  "FIGMA_TO_WEBFLOW",
  "FIGMA_TO_POWERPOINT",
  "GOOGLESLIDES_TO_FIGMA",
  "MIRO_TO_FIGJAM",
  "MIRO_TO_LUCIDCHART",
  "PDF_TO_INDD",
  "POWERPOINT_TO_CANVA",
  "WEBSITE_TO_FIGMA",
  "XD_TO_JPG",
  "XD_TO_POWERPOINT",
  "FREEHAND_TO_FIGJAM",
  "LUCIDCHART_TO_MIRO",
  "LUCIDCHART_TO_FIGJAM",
  "XD_TO_CANVA",
  "MIRO_TO_MURAL",
  "PSD_TO_CANVA",
  "MURAL_TO_MIRO",
  "FIGMA_TO_INDD",
  "VISIO_TO_FIGJAM",
];

export const conversionFormats: IConversionType[] = [
  "XD_TO_SKETCH",
  "XD_TO_FIGMA",
  "XD_TO_PSD",
  "XD_TO_AI",
  "FIGMA_TO_SKETCH",
  "FIGMA_TO_XD",
  "FIGMA_TO_PSD",
  "FIGMA_TO_AI",
  "FIGMA_TO_GOOGLESLIDES",
  "FIGMA_TO_CANVA",
  "SKETCH_TO_XD",
  "SKETCH_TO_FIGMA",
  "SKETCH_TO_PSD",
  "SKETCH_TO_AI",
  "AI_TO_FIGMA",
  "AI_TO_SKETCH",
  "PDF_TO_FIGMA",
  "PDF_TO_XD",
  "PDF_TO_PSD",
  "PDF_TO_SKETCH",
  "PSD_TO_SKETCH",
  "PSD_TO_FIGMA",
  "GOOGLESLIDES_TO_FIGMA",
  "GOOGLESLIDES_TO_CANVA",
  "EPS_TO_FIGMA",
  "CANVA_TO_FIGMA",
  "INDD_TO_FIGMA",
  "INDD_TO_XD",
  "INDD_TO_SKETCH",
  "FIGMA_TO_POWERPOINT",
  "FIGMA_TO_WEBFLOW",
  "MIRO_TO_FIGJAM",
  "POWERPOINT_TO_FIGMA",
  "POWERPOINT_TO_SKETCH",
  "POWERPOINT_TO_CANVA",
  "POWERPOINT_TO_PSD",
  "POWERPOINT_TO_XD",
  "POWERPOINT_TO_AI",
  "PDF_TO_AI",
  "FIGMA_TO_BUBBLE",
  "PDF_TO_INDD",
  "FIGJAM_TO_MIRO",
  "FIGJAM_TO_LUCIDCHART",
  "WEBSITE_TO_FIGMA",
  "FIGMA_TO_AXURE",
  "EXPRESS_TO_FIGMA",
  "EXPRESS_TO_CANVA",
  "FIGMA_TO_AFTEREFFECTS",
  "FIGMA_TO_PDF",
  "XD_TO_PDF",
  "CANVA_TO_POWERPOINT",
  "CANVA_TO_GOOGLESLIDES",
  "CANVA_TO_AI",
  "CANVA_TO_INDD",
  "CANVA_TO_PSD",
  "CANVA_TO_HTML",
  "CANVA_TO_WORDPRESS",
  "CANVA_TO_XD",
  "XD_TO_JPG",
  "XD_TO_POWERPOINT",
  "FREEHAND_TO_FIGJAM",
  "LUCIDCHART_TO_MIRO",
  "LUCIDCHART_TO_FIGJAM",
  "MIRO_TO_LUCIDCHART",
  "XD_TO_CANVA",
  "MIRO_TO_MURAL",
  "PSD_TO_CANVA",
  "MURAL_TO_MIRO",
  "KEYNOTE_TO_FIGMA",
  "KEYNOTE_TO_XD",
  "KEYNOTE_TO_SKETCH",
  "FIGMA_TO_INDD",
  "VISIO_TO_FIGJAM",
];
