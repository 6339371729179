const baseUrl = process.env.NEXT_PUBLIC_XD2SKETCH_BACKEND_HOST;
const repairXdServiceUrl =
  process.env.NEXT_PUBLIC_XD2SKETCH_REPAIR_XD_FILE_HOST;

if (baseUrl === undefined) {
  throw new Error(
    "API url isn't set, this can result in unexpected behaviour!",
  );
}

if (repairXdServiceUrl === undefined) {
  throw new Error(
    "The url for file-repair-service isn't set, this can result in unexpected behaviour!",
  );
}

export const BACKEND_ENDPOINTS = {
  // auth
  getProfile: `${baseUrl}/user/me`,
  loginPopup: (origin: string) =>
    `${baseUrl}/user/login?callback=${origin}/api/logged-in`,
  logout: `${baseUrl}/user/logout`,

  // user
  userExists: `${baseUrl}/user/exists`,
  updateUser: `${baseUrl}/user/update`,
  userInvoices: `${baseUrl}/user/invoices`,
  downloadInvoicePdf: (invoiceId: string) =>
    `${baseUrl}/user/invoices/${invoiceId}/pdf`,
  storeFigmaAccessToken: `${baseUrl}/user/auth/figma-access-token`,

  // converter
  upload: `${baseUrl}/upload`,
  uploadFigma: `${baseUrl}/upload/figma`,
  uploadFigJam: `${baseUrl}/upload/figjam`,
  uploadGoogleSlides: `${baseUrl}/upload/google-slides`,
  uploadCanva: `${baseUrl}/upload/canva`,
  uploadStatus: `${baseUrl}/upload/status`,
  fileDetails: `${baseUrl}/download/info`,
  convertFile: `${baseUrl}/convert`,
  convertStatus: `${baseUrl}/convert/status`,

  // payment
  payment: `${baseUrl}/payment/hosted-page`,
  paymentStatus: `${baseUrl}/payment/status`,
  payWithCredits: `${baseUrl}/payment/use-credits`,
  paymentVerify: `${baseUrl}/payment/verify`,

  // converted files
  multipleFileDetails: `${baseUrl}/download/infos`,
  convertedFilesOfUser: `${baseUrl}/user/files`,
  delete: `${baseUrl}/files/delete/by-source`,

  // misc
  newsletter: "https://havealooklabs.com/mailchimp.php",
  betaSignup: "https://havealooklabs.com/mailchimp.php",
  converterBetaSignUp: `${baseUrl}/signups/beta`,
  salesInquiryForm: "https://usebasin.com/f/e1f93d9b1aaf",
  contactForm: "https://usebasin.com/f/031cbdc53051",
};

export const REPAIR_XD_FILE_ENDPOINTS = {
  upload: `${repairXdServiceUrl}/upload`,
  uploadStatus: `${repairXdServiceUrl}/upload/status`,
  downloadUrl: `${repairXdServiceUrl}/download/url`,
  repair: `${repairXdServiceUrl}/repair`,
};
