import { CssFelaStyle } from "react-fela";

import { theme } from "@/hooks/useTheme/theme";

import { IButton } from "../types";
import { variantMap } from "../variants";

export const getButtonStyles = (
  variant: IButton["variant"],
  disabled = false,
) => {
  const { colors } = theme;
  const currentVariant = disabled
    ? variantMap["DISABLED"]
    : variant && variant in variantMap
    ? variantMap[variant]
    : variantMap["PRIMARY"];

  const color = colors[currentVariant.color];
  const backgroundColor = colors[currentVariant.backgroundColor];
  const borderColor = colors[currentVariant.borderColor];

  const styles: CssFelaStyle<any, any> = {
    color,
    width: "100%",
    height: 46,
    fontSize: 14,
    fontWeight: "bold",
    textTransform: "uppercase",
    letterSpacing: 1,
    borderRadius: 8,
    backgroundColor,
    outline: "none",
    cursor: disabled ? undefined : "pointer",
    transition: "opacity .15s",
    border: `1px solid ${borderColor}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    whiteSpace: "nowrap",
    nested: {
      ":hover": {
        opacity: disabled ? 1 : 0.85,
      },
    },
  };

  return styles;
};
