import React from "react";

interface IContext {
  isSalesInquiryFormVisible: boolean;
  areDiscountsEnabled: boolean;
}

type IAction =
  | {
      type: "setIsSalesInquiryFormVisible";
      payload: boolean;
    }
  | {
      type: "setAreDiscountsEnabled";
      payload: boolean;
    };

const reducer = (state: IContext, action: IAction): IContext => {
  switch (action.type) {
    case "setIsSalesInquiryFormVisible":
      return { ...state, isSalesInquiryFormVisible: action.payload };
    case "setAreDiscountsEnabled":
      return { ...state, areDiscountsEnabled: action.payload };
    default:
      return state;
  }
};

const AppContext = React.createContext<
  (IContext & { dispatch: React.Dispatch<IAction> }) | null
>(null);

export const useAppContext = () => {
  const context = React.useContext(AppContext);

  if (!context) {
    throw new Error("useAppContext must be used within a AppContextProvider");
  }
  return context;
};

export const AppContextProvider: React.FC = props => {
  const [state, dispatch] = React.useReducer(reducer, {
    isSalesInquiryFormVisible: false,
    areDiscountsEnabled: false,
  } as IContext);

  return <AppContext.Provider value={{ ...state, dispatch }} {...props} />;
};
