import React from "react";
import { useFela } from "react-fela";
import useTranslation from "next-translate/useTranslation";

import { useTheme } from "@/hooks/useTheme";
import { Card } from "@/components/Card";
import { Link } from "@/components/Link";
import { Icon, StyledIconWrapper } from "@/components/Icon";
import { IDropdownChildren } from "@/components/Dropdown";

import { content } from "./content";

export const SolutionsDropdown: React.FC<IDropdownChildren> = ({ onClose }) => {
  const { css } = useFela();
  const { colors, bp } = useTheme();
  const { t } = useTranslation();

  const { groups } = content;

  return (
    <Card
      customStyle={{
        flex: 1,
        padding: 0,
        borderRadius: 8,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: colors.WHITE,
        boxShadow: `20px 20px 80px ${colors.CETACEAN_BLUE}`,
        position: "absolute",
        top: 0,
        [bp.ONLY_MOBILE]: {
          boxShadow: "none",
          overflowY: "hidden",
          position: "relative",
        },
        [bp.FROM_TABLET]: {
          width: 450,
          padding: 0,
          maxWidth: "100%",
          position: "relative",
        },
      }}
    >
      <div
        className={css({
          display: "flex",
          flexDirection: "column",
          padding: 10,
          flexWrap: "wrap",
          justifyContent: "space-between",
          [bp.FROM_TABLET]: {
            justifyContent: "flex-start",
          },
        })}
      >
        {groups.map(group => (
          <Link
            href={group.href}
            key={group.title}
            target={group.external ? "_blank" : ""}
            rel={group.external ? "noreferrer" : ""}
            onClick={onClose}
            customStyle={{
              display: "flex",
              textDecoration: "none",
              alignItems: "flex-start",
              flexDirection: "row",
              padding: 20,
              width: "100%",
              cursor: "pointer",
              marginBottom: 5,
              [bp.ONLY_MOBILE]: {
                padding: "20px 0",
              },
              [bp.FROM_TABLET]: {
                display: "flex",
                nested: {
                  ":hover": {
                    background: colors.GHOST_WHITE1,
                    borderRadius: 8,
                  },
                  ":not(:last-child)": {
                    marginRight: 32,
                  },
                },
              },
            }}
          >
            <StyledIconWrapper customStyle={{ marginRight: 26 }}>
              <Icon iconType={group.icon} />
            </StyledIconWrapper>
            <div
              className={css({
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
              })}
            >
              <h3
                className={css({
                  fontSize: 14,
                  fontWeight: "normal",
                  marginTop: 0,
                  color: colors.DARK_GREY,
                  display: "flex",
                  alignItems: "center",
                })}
              >
                <strong>
                  {t(`navigation:solutionsDropdown.${group.title}` as string)}
                </strong>
              </h3>
              <p
                className={css({
                  fontSize: 14,
                  color: colors.DARK_GREY,
                  fontWeight: "normal",
                })}
              >
                {t(
                  `navigation:solutionsDropdown.${group.title}Description` as string,
                )}
              </p>
            </div>
          </Link>
        ))}
      </div>
    </Card>
  );
};
