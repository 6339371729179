import React from "react";

export function useOnClickOutside(
  ref: React.RefObject<HTMLElement> | Array<React.RefObject<HTMLElement>>,
  handler?: (event: MouseEvent) => void,
) {
  React.useEffect(() => {
    const listener: EventListener = (event: any) => {
      const refs = Array.isArray(ref) ? ref : [ref];
      const elementId = event.target.querySelector('[id$="-modal"]')?.id;

      for (const ref of refs) {
        if (
          !ref.current ||
          ref.current.contains(event.target) ||
          elementId !== ref.current.id
        ) {
          return;
        }
      }

      if (handler) {
        handler(event);
      }
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}
