import React from "react";
import { useFela } from "react-fela";
import { IWithCustomStyle } from "@/hooks/useTheme/types";

type BackgroundColorVariant = "dark" | "light";

interface IBackground {
  variant?: BackgroundColorVariant;
}

type IProps = IBackground & IWithCustomStyle;

const colorMap: { [variant in BackgroundColorVariant]: string } = {
  dark: "rgba(0, 0, 0, 0.5)",
  light: "rgba(255,255,255,0.5)",
};

export const Background: React.FC<IProps> = ({
  variant,
  customStyle = {},
  ...rest
}) => {
  const { css } = useFela();

  const color = variant ? colorMap[variant] : undefined;
  return (
    <div
      className={css(
        {
          position: "fixed",
          top: 0,
          right: 0,
          left: 0,
          padding: 20,
          zIndex: 100,
          height: "100vh",
          display: "flex",
          alignItems: "center",
          backgroundColor: color ?? "inherit",
        },
        customStyle,
      )}
      {...rest}
    />
  );
};
