import Script from "next/script";
import React from "react";

export const ChargebeeScript: React.FC = () => (
  <>
    <Script
      strategy="beforeInteractive"
      src="https://js.chargebee.com/v2/chargebee.js"
      data-cb-site={process.env.NEXT_PUBLIC_XD2SKETCH_CHARGEBEE_SITE}
      data-cb-gtm-enabled="true"
    />
  </>
);
