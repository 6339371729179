import {
  IConversionTarget,
  IConversionType,
} from "@/containers/Converter/types";

import { getConversionDetails } from ".";

type IConversionFormats = Partial<
  Record<IConversionTarget, IConversionTarget[]>
>;

export const getConversionFormats = (
  conversionTypes: IConversionType[],
): IConversionFormats => {
  return conversionTypes.reduce<IConversionFormats>((acc, conversionType) => {
    const {
      conversionSource: source,
      conversionTarget: target,
    } = getConversionDetails(conversionType);

    if (!acc[source]) {
      acc[source] = [target];
      return acc;
    }

    if (acc[source]?.includes(target)) {
      return acc;
    }

    acc[source]?.push(target);
    return acc;
  }, {});
};
